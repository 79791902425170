//
// Typography
////////////////////////////////////////////////////////////////////////////////


h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;

}

h1 {
    font-weight: 400;
}

h2 {
    font-size: 25px;
    font-weight: 400;
}

h3 {
    font-size: 25px;
    margin-bottom: 10px;
    font-weight: 300;
}

h4 {

}

h5 {

}

h6 {

}

a {
    cursor: pointer;
}

strong {

}

em {
    font-style: italic;
}

p {
    font-size: 17px;
    margin-bottom: 10px;

    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    small {
        font-size: 16px;
    }

    &.disclaimer {
        font-size: 12px !important;
        color: #fff;
    }
}

li {

}
