//
// loading container
////////////////////////////////////////////////////////////////////////////////

.loading-container {
    /*min-height: 300px;*/

    &--content {
        min-height: 240px;
    }

    &--button {
        padding: 32px 0;
        border-top: 2px #fff solid;
        border-bottom: 2px #fff solid;

        @include max-breakpoint($screen-xs-max) {
            padding: 60px 0;
        }
    }

    &.is-minimal {
        .loading-container--button {
            display: none;
        }
    }
}
