//
// Pokémon Page
////////////////////////////////////////////////////////////////////////////////////////////////

body[data-page="pokemon"] {
    // navigation active state
    .navigation {
        &--pokemon {
            a {
                font-weight: 700;

                &:after {
                    @include sprite(-120px -308px);
                }
            }

            &.is-disabled {
                a {
                    &:hover::after,
                    &:after {
                        @include sprite(-120px -308px);
                    }
                }
            }
        }

        // sprite scaled to 0.75
        @include range-breakpoint($screen-sm, 864px) {
            &--pokemon {
                a:after {
                    @include sprite(-90px -231px);
                    background-size: 310.5px auto;
                }

                &.is-disabled {
                    a {
                        &:hover::after,
                        &:after {
                            @include sprite(-90px -231px);
                            background-size: 310.5px auto;
                        }
                    }
                }
            }
        }
    }

    .content--movement-side {
        position: absolute;
        bottom: auto;
        top: auto;
        left: auto;
        z-index: 0;
        background-size: auto;
        background-repeat: no-repeat;
    }

    .sect-pokemon-main {
        padding-top: 300px;
        padding-bottom: 20px;

        .content-hero--background-animate {
          background-image: url('#{$image-path}/sections/content-pokemon-main.jpg');
          background-position: center top;
        }

        .content--movement-side {
            display: none;
        }
    }

    .sect-pokemon-catch {
        padding-top: 75px;
        padding-bottom: 100px;
        background-image: url('#{$image-path}/sections/content-pokemon-catch.jpg');

        .content--movement-side {
            width: 928px;
            height: 619px;
            left: 0;
            bottom: -100px;
            background-image: url('#{$image-path}/sections/side-pokemon-catch.png');
            background-position: left top;
        }
    }

    .sect-pokemon-pokedex {
        padding-top: 70px;
        padding-bottom: 110px;
        background-image: url('#{$image-path}/sections/content-pokemon-pokedex.jpg');

        .content--movement-side {
            width: 810px;
            height: calc(100% + 120px);
            right: -200px;
            bottom: -110px;
            background-image: url('#{$image-path}/sections/side-pokemon-pokedex.png');
            background-position: right top;
        }
    }

    .sect-pokemon-traits {
        padding-top: 90px;
        padding-bottom: 120px;
        background-image: url('#{$image-path}/sections/content-pokemon-traits.jpg');

        .content--movement-side {
            width: 569px;
            height: 668px;
            left: -200px;
            bottom: -120px;
            background-image: url('#{$image-path}/sections/side-pokemon-traits.png');
            background-position: left top;

            @include max-breakpoint($screen-md-max) {
                left: -280px;
            }
        }
    }

    .sect-pokemon-evolution {
        padding-top: 80px;
        padding-bottom: 100px;
        background-image: url('#{$image-path}/sections/content-pokemon-evolution.jpg');

        .content--movement-side {
            width: 465px;
            height: 550px;
            right: -50px;
            bottom: -87px;
            background-image: url('#{$image-path}/sections/side-pokemon-evolution.png');
            background-position: right top;
        }
    }

    .sect-pokemon-eggs {
        padding-top: 80px;
        padding-bottom: 160px;
        background-image: url('#{$image-path}/sections/content-pokemon-eggs.jpg');

        .content--movement-side {
            width: 812px;
            height: 682px;
            left: -75px;
            top: -10px;
            background-image: url('#{$image-path}/sections/side-pokemon-eggs.png');
            background-position: left top;
        }

        .content--movement-aux {
            position: absolute;
        }
        .content--movement-aux-1 {
            width: 116px;
            height: 141px;
            right: 0;
            bottom: -150px;
            z-index: 3;
            background-image: url('#{$image-path}/sections/aux-pokemon-eggs-1.png');
        }

        p.disclaimer {
            width: 240px;
            position: absolute;
            bottom: 240px;
            left: 100px;
        }

        .content--movement-aux-2 {
            width: 83px;
            height: 110px;
            right: 93px;
            bottom: -100px;
            z-index: 2;
            background-image: url('#{$image-path}/sections/aux-pokemon-eggs-2.png');
        }
    }

    @include max-breakpoint($screen-xs-max) {
        .sect-pokemon-main {
            padding-top: 40px;
            padding-bottom: 100px;

            .content-hero--background-animate {
                background-image: url('#{$image-path}/sections/content-pokemon-main-mobile.jpg');
                background-size: 100% auto;
                background-position: center bottom;
            }
        }

        .sect-pokemon-catch {
            background-image: url('#{$image-path}/sections/content-pokemon-catch-mobile.jpg');
            background-size: 100% auto;
            background-position: center bottom;
            padding-top: 40px;
            padding-bottom: 104%;

            &.content-side-text-right .box-capped .box--bottom-cap::after {
                left: 29%;
            }
        }
        .sect-pokemon-pokedex {
            background-image: url('#{$image-path}/sections/content-pokemon-pokedex-mobile.jpg');
            background-size: 100% auto;
            background-position: center bottom;
            padding-top: 40px;
            padding-bottom: 62%;

            .content--movement-side {
                display: none;
            }

            &.content-side-text-left .box-capped .box--bottom-cap::after {
                left: 78%;
            }
        }
        .sect-pokemon-traits {
            background-image: url('#{$image-path}/sections/content-pokemon-traits-mobile.jpg');
            background-size: 100% auto;
            background-position: center bottom;
            padding-top: 40px;
            padding-bottom: 112%;

            &.content-side-text-right .box-capped .box--bottom-cap::after {
                left: 55%;
            }
        }
        .sect-pokemon-evolution {
            background-image: url('#{$image-path}/sections/content-pokemon-evolution-mobile.jpg');
            background-size: 100% auto;
            background-position: center bottom;
            padding-top: 40px;
            padding-bottom: 103%;

            &.content-side-text-left .box-capped .box--bottom-cap::after {
                left: 55%;
            }
        }
        .sect-pokemon-eggs {
            background-image: url('#{$image-path}/sections/content-pokemon-eggs-mobile.jpg');
            background-size: 100% auto;
            background-position: center bottom;
            padding-top: 40px;
            padding-bottom: 84%;

            .content--movement-aux {
                display: none;
            }

            &.content-side-text-right .box-capped .box--bottom-cap::after {
                left: 78%;
            }
        }
    }

    @include max-breakpoint($screen-xs-max) {
        .content--movement-side {
            background: none !important; // Killing load of precise desktop selectors for background images
            display: none;
        }
    }
}
