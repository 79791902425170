.flag {
    @include inline-middle();
    position: relative;
    width: 24px;
    height: 18px;
    overflow: hidden;
    background: #000;
    background-image: url($image-flag-sprite);
    background-repeat: no-repeat;
    background-size: 24px auto;
    display: inline-block;

    // australia
    &-au {
        background-position: 0 -216px;
    }

    // belgium
    &-be {
        background-position: 0 -198px;
    }

    // canada
    &-ca {
        background-position: 0 -180px;
    }

    // germany
    &-de {
        background-position: 0 -162px;
    }

    // spain
    &-es {
        background-position: 0 -144px;
    }

    // france
    &-fr {
        background-position: 0 -126px;
    }

    // Italy
    &-it {
        background-position: 0 -90px;
    }

    // Latin America
    &-la {
        background-position: 0 -72px;
    }

    // The Netherlands
    &-nl {
        background-position: 0 -54px;
    }

    // Portugal
    &-pt {
        background-position: 0 -36px;
    }

    // Russia
    &-ru {
        background-position: 0 -18px;
    }

    // UK/Great Britain
    &-uk {
        background-position: 0 -108px;
    }

    // USA
    &-us {
        background-position: 0 0;
    }
}



.btn-region {
    .flag {
        /*border: 1px #fff solid;*/
        width: 24px;
        height: 24px;
        border-radius: 12px;
        background-size: 32px auto;
        position: absolute;
        top: 4px;
        right: 8.5px;
        z-index: 2;
        box-shadow: 0 0 1px #fff;

        // australia
        &-au {
            background-position: -4px -288px;
        }

        // belgium
        &-be {
            background-position: -4px -264px;
        }

        // canada
        &-ca {
            background-position: -4px -240px;
        }

        // germany
        &-de {
            background-position: -4px -216px;
        }

        // spain
        &-es {
            background-position: -4px -192px;
        }

        // france
        &-fr {
            background-position: -4px -168px;
        }

        // Italy
        &-it {
            background-position: -4px -120px;
        }

        // Latin America
        &-la {
            background-position: -4px -96px;
        }

        // The Netherlands
        &-nl {
            background-position: -4px -72px;
        }

        // Portugal
        &-pt {
            background-position: -4px -48px;
        }

        // Russia
        &-ru {
            background-position: -4px -24px;
        }

        // UK/Great Britain
        &-uk {
            background-position: -4px -144px;
        }

        // USA
        &-us {
            background-position: -4px 0;
        }
    }

    @include max-breakpoint($screen-xs-max) {
        font-size: 20px;
        padding: 10px 59px 10px 22px;

        .flag {
            top: 8px;
            right: 10px;
            transform-origin: 50% 50%;
            transform: scale(1.1);
        }

        &:after {
            transform: scale(1.1);
            top: 2px;
            right: -1px;
        }
    }

    @include max-breakpoint(404px) {
        font-size: 16px;
        padding: 8px 54px 8px 18px;

        .flag {
            top: 4px;
            right: 7.5px;
            transform-origin: 50% 50%;
            transform: scale(1);
        }

        &:after {
            transform: scale(1);
            top: 0;
            right: -1px;
        }
    }
}
