.list-inline {
    @include inline-base();
    margin: 0;
    padding: 0;

    li {
        @include inline-middle();
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
}

// for lines of text with multiple weights
.multi-weight {
    .light {
        font-weight: 300;
    }
    strong {
        font-weight: 700;
    }
}

.word {
    white-space: nowrap;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

// uppercase
.text-upper {
    text-transform: uppercase;
}

// lower
.text-lower {
    text-transform: lowercase;
}

// text aligns
.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-nowrap {
    white-space: nowrap;
}

.text-wrap {
    white-space: normal;
}

@for $i from 1 through 12 {
    .z-#{$i} {
      position: relative;
      z-index: $i;
    }
}

// mobile text aligns
@include max-breakpoint($screen-xs-max) {
    .text-right-xs {
        text-align: right;
    }

    .text-left-xs {
        text-align: left;
    }

    .text-center-xs {
        text-align: center;
    }

    .text-nowrap-xs {
        white-space: nowrap;
    }

    .text-wrap-xs {
        white-space: normal;
    }
}

@include min-breakpoint($screen-sm) {
    .mobile-only {
        display: none;
    }
}
