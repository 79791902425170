//
// Limelight video player...
////////////////////////////////////////////////////////////////////////////////

.LimelightEmbeddedPlayer {
    .limelight-player-footprint {
        height: 100% !important;
    }

    .limelight-player-viewport {
        width: 100% !important;
        height: 100% !important;
    }

    video {
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
    }
}
