//
// Boxes with black translucent background
////////////////////////////////////////////////////////////////////////////////

.box {
    border-radius: 6px;
    padding: 10px 15px;
    text-align: left;

    h2 {
        color: $color-dark-grey;
        font-weight: 300;
        font-size: 35px;
        margin-bottom: 20px;
    }

    hr {
        border: 0;
        height: 1px;
        background: #fff;
    }

    p {
        font-size: 20px;
        font-weight: 400;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .content--description {
        padding-right: 0;
        padding-left: 0;
    }

    &-wrap {
        display: inline-block;
    }

    // large subclass
    &-large {
        padding: 21px 35px 35px 35px;

        @include max-breakpoint ($screen-xs-max) {
            padding: 20px;
        }
    }

    // inline-block
    &-inline {
        @include inline-middle();
    }

    // inline-block
    &-shadow {
        box-shadow: 0 0 10px rgba(0,0,0,0.3);
    }

    // bordered subclass
    &-bordered {
        border: 1px #fff solid;
    }

    // flyout (has edge to bleed)
    &-flyout {
        position: relative;
        padding: 28px 32px 34px 32px;
        border-radius: 12px;

        &:before {
            content: '';
            display: block;
            width: 40vw;
            height: 100%;
            position: absolute;
            top: 0;
            background: rgba(0,0,0,0.333);
        }

        @include max-breakpoint ($screen-xs-max) {
            &:before {
                display: none;
            }
        }
    }

    &-flyout-left {
        border-radius: 0 12px 12px 0;
        padding-left: 0;

        &:before {
            right: 100%;
        }

        @include max-breakpoint ($screen-xs-max) {
            border-radius: 12px;
            padding: 28px 18px 34px 18px;
        }
    }

    &-flyout-right {
        border-radius: 12px 0 0 12px;
        padding-right: 0;

        &:before {
            left: 100%;
        }

        @include max-breakpoint ($screen-xs-max) {
            border-radius: 12px;
            padding: 28px 18px 34px 18px;
        }
    }

    &-dark {
        background: $color-black-trans;

        p {
            color: #fff;
        }

        h2 {
            color: #fff;
        }

        .box--inner {
            background: $color-black-trans;
        }
    }

    &-light {
        background: $color-white-trans;

        p {
            color: $color-dark-grey;
        }

        .box--inner {
            background: $color-white-trans;
        }
    }

    &-centered {
        max-width: 555px;
        margin-left: auto;
        margin-right: auto;
    }

    &-capped {
        background: transparent;
        border-radius: 0;
        padding: 0;

        .box--inner {
            padding: 10px 15px;
            border-radius: 6px 6px 0 0;
        }

        .box--bottom-cap {
            border-radius: 0 0 6px 6px;
            padding: 10px 15px;
            background: $color-grey-bg-trans;
            position: relative;

            p {
                color: #fff;
                margin: 0;
                font-size: 16px;
            }
        }

        &.box-dark {
            .box--bottom-cap {
                background: $color-black-trans-dark;
            }
        }

        &.box-large {

            .box--inner {
                padding: 21px 35px 35px 35px;

                @include max-breakpoint ($screen-xs-max) {
                    padding: 20px;
                }
            }
            .box--bottom-cap {
                padding: 21px 35px;

                @include max-breakpoint ($screen-xs-max) {
                    padding: 20px;
                }
            }
        }
    }

    &-blue {
        h2 {
            color: $color-blue;
            font-weight: 300;
            font-size: 35px;
        }

        .box--bottom-cap {
            background: $color-blue-bg-trans;
            color: #fff;
        }
    }

    &-green {
        h2 {
            color: $color-green;
            font-weight: 300;
            font-size: 35px;
        }

        .box--bottom-cap {
            background: $color-green-bg-trans;
            color: #fff;
        }
    }

    &-orange {
        h2 {
            color: $color-orange;
            font-weight: 300;
            font-size: 35px;
        }

        .box--bottom-cap {
            background: $color-orange-bg-trans;
            color: #fff;
        }
    }

    &-purple {
        h2 {
            color: $color-purple;
            font-weight: 300;
            font-size: 35px;
        }

        .box--bottom-cap {
            background: $color-purple-bg-trans;
            color: #fff;
        }
    }

    &-text-large {
        h2 {
            font-size: 25px;

            @include max-breakpoint(359px) {
                font-size: 22px;
            }
        }
    }

    &-text-small {
        p {
            font-size: 18px;
        }
    }

    &-text-xsmall {
        p {
            font-size: 16px;
        }

        h2 {
            font-size: 28px;
        }
    }

    &-side-image {
        padding-left: 196px;

        @include max-breakpoint($screen-sm-max) {
            padding-left: 20px;
            padding-top: 196px;
        }
    }
}

.content-flyout-left,
.content-side-text-left {
    .box-capped {
        .box--bottom-cap {
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: calc(50% - 12px);
                left: 100%;
                border-left: 16px $color-grey-bg-trans solid;
                border-right: 0;
                border-top: 12px transparent solid;
                border-bottom: 12px transparent solid;

                @include max-breakpoint($screen-xs-max) {
                    left: calc(50% - 8px);
                    top: 100%;
                    border-top: 16px $color-grey-bg-trans solid;
                    border-bottom: 0;
                    border-left: 12px transparent solid;
                    border-right: 12px transparent solid;
                }
            }
        }

        &.box-blue {
            .box--bottom-cap {
                &:after {
                    border-left-color: $color-blue-bg-trans;

                    @include max-breakpoint($screen-xs-max) {
                        border-left-color: transparent;
                        border-top-color: $color-blue-bg-trans;
                    }
                }
            }
        }

        &.box-green {
            .box--bottom-cap {
                &:after {
                    border-left-color: $color-green-bg-trans;

                    @include max-breakpoint($screen-xs-max) {
                        border-left-color: transparent;
                        border-top-color: $color-green-bg-trans;
                    }
                }
            }
        }

        &.box-orange {
            .box--bottom-cap {
                &:after {
                    border-left-color: $color-orange-bg-trans;

                    @include max-breakpoint($screen-xs-max) {
                        border-left-color: transparent;
                        border-top-color: $color-orange-bg-trans;
                    }
                }
            }
        }

        &.box-purple {
            .box--bottom-cap {
                &:after {
                    border-left-color: $color-purple-bg-trans;

                    @include max-breakpoint($screen-xs-max) {
                        border-left-color: transparent;
                        border-top-color: $color-purple-bg-trans;
                    }
                }
            }
        }

        &.box-dark {
            .box--bottom-cap {
                border-radius: 0 0 6px 0;

                &:before {
                    content: '';
                    display: block;
                    width: 40vw;
                    height: calc(100% - 0.5px);
                    position: absolute;
                    top: 0;
                    right: 100%;
                    background: rgba(0,0,0,0.333);

                    @include max-breakpoint($screen-xs-max) {
                        display: none;
                    }
                }

                &:after {
                    border-left-color: $color-black-trans-dark;

                    @include max-breakpoint($screen-xs-max) {
                        border-left-color: transparent;
                        border-top-color: $color-black-trans-dark;
                    }
                }

                @include max-breakpoint($screen-xs-max) {
                    border-radius: 0 0 6px 6px;
                }
            }
        }
    }
}

.content-flyout-right,
.content-side-text-right {
    .box-capped {
        .box--bottom-cap {
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: calc(50% - 12px);
                right: 100%;
                border-right: 16px $color-grey-bg-trans solid;
                border-left: 0;
                border-top: 12px transparent solid;
                border-bottom: 12px transparent solid;

                @include max-breakpoint($screen-xs-max) {
                    left: calc(50% - 8px);
                    top: 100%;
                    border-top: 16px $color-grey-bg-trans solid;
                    border-bottom: 0;
                    border-left: 12px transparent solid;
                    border-right: 12px transparent solid;
                }
            }
        }

        &.box-blue {
            .box--bottom-cap {
                &:after {
                    border-right-color: $color-blue-bg-trans;

                    @include max-breakpoint($screen-xs-max) {
                        border-right-color: transparent;
                        border-top-color: $color-blue-bg-trans;
                    }
                }
            }
        }

        &.box-green {
            .box--bottom-cap {
                &:after {
                    border-right-color: $color-green-bg-trans;

                    @include max-breakpoint($screen-xs-max) {
                        border-right-color: transparent;
                        border-top-color: $color-green-bg-trans;
                    }
                }
            }
        }

        &.box-orange {
            .box--bottom-cap {
                &:after {
                    border-right-color: $color-orange-bg-trans;

                    @include max-breakpoint($screen-xs-max) {
                        border-right-color: transparent;
                        border-top-color: $color-orange-bg-trans;
                    }
                }
            }
        }

        &.box-purple {
            .box--bottom-cap {
                &:after {
                    border-right-color: $color-purple-bg-trans;

                    @include max-breakpoint($screen-xs-max) {
                        border-right-color: transparent;
                        border-top-color: $color-purple-bg-trans;
                    }
                }
            }
        }

        &.box-dark {
            .box--bottom-cap {
                &:before {
                    content: '';
                    display: block;
                    width: 40vw;
                    height: calc(100% - 0.5px);
                    position: absolute;
                    top: 0;
                    left: 100%;
                    background: rgba(0,0,0,0.333);
                }
                &:after {
                    border-right-color: $color-black-trans-dark;

                    @include max-breakpoint($screen-xs-max) {
                        border-right-color: transparent;
                        border-top-color: $color-black-trans-dark;
                    }
                }
            }
        }
    }
}
