//
// Photos Page
////////////////////////////////////////////////////////////////////////////////////////////////

body[data-page="photos"] {
    // navigation active state
    .navigation {
        &--photos {
            a {
                font-weight: 700;

                &:after {
                    @include sprite(-240px -308px);
                }
            }

            &.is-disabled {
                a {
                    &:hover::after,
                    &:after {
                        @include sprite(-240px -308px);
                    }
                }
            }
        }

        // sprite scaled to 0.75
        @include range-breakpoint($screen-sm, 864px) {
            &--photos {
                a:after {
                    @include sprite(-180px -231px);
                    background-size: 310.5px auto;
                }

                &.is-disabled {
                    a {
                        &:hover::after,
                        &:after {
                            @include sprite(-180px -231px);
                            background-size: 310.5px auto;
                        }
                    }
                }
            }
        }
    }

    .sect-photos-main {
        .content-hero--background-animate {
            background-image: url('#{$image-path}/sections/content-photos-main.jpg');
        }

        .content--movement-side {
            width: 274px;
            height: 476px;
            top: auto;
            bottom: -110px;
            background-image: url('#{$image-path}/sections/side-photos-main.png');
            z-index: 1;
        }

        @include max-breakpoint($screen-xs-max) {
            padding: 40px 0;
        }
    }

    .sect-photos-gallery {
        background-image: url('#{$image-path}/sections/content-photos-gallery.jpg');
        background-color: #71d4ed;

        .box {
            margin-bottom: 40px;
        }

        @include max-breakpoint($screen-xs-max) {
            padding-top: 20px;
            padding-bottom: 40px;
        }
    }

    @include max-breakpoint($screen-xs-max) {
        .content--movement-side {
            background: none !important; // Killing load of precise desktop selectors for background images
            display: none;
        }
    }
}
