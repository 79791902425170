//
// Portuguese
////////////////////////////////////////////////////////////////////////////////

html[lang="pt"] {
    // appropriate app store language
    .btn-appstore-apple {
        background-position: 0 -200px;
    }
    .btn-appstore-google {
        background-position: -138px -200px;
    }

    @include range-breakpoint($screen-sm, $nav-small-breakpoint) {
        .navigation--wrapper a::after {
            margin-top: 9px;
        }
        .navigation--purchases {
            a {
                &:after {
                    position: relative;
                    left: -1px;
                }
            }
        }
    }

    // social box w/ long label cleanup
    .footer--social-box {
        span {
            display: block;
            margin-bottom: 4px;
        }

        li:first-child {
            margin-left: 0;
        }
    }

    // homepage hero vertical sizing on mobile
    body[data-page="homepage"] {

        // hero h1 size for gradient text to behave
        .content-hero h1 {
            font-size: 70px;
        }

        @include range-breakpoint($screen-sm, 800px) {
            .content-hero h1 {
                font-size: 65px;
            }
            .btn.btn-play {
                transform: scale(0.9);
            }
            .phone-display h3 {
                font-size: 28px;
            }
        }

        @include max-breakpoint($screen-xs-max) {
            .btn.btn-play {
                transform: translateX(-55%) scale(1);
            }
            .content-hero {
                &--background {
                    height: 558px;
                }
                &::after {
                    top: 406px;
                }
                .content--movement {
                    height: calc(100% + 499px);
                }

                &.has-video {
                    .content-hero--background {
                        height: 574px;
                    }
                    &::after {
                        top: 422px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 618px;
                    }
                    &::after {
                        top: 466px;
                    }
                    .content--movement {
                        height: calc(100% + 499px);
                    }

                    &.has-video {
                        .content-hero--background {
                            height: 634px;
                        }
                        &::after {
                            top: 482px;
                        }
                        .content--movement {
                            height: calc(100% + 530px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint(540px) {
            .btn.btn-play {
                transform: translateX(-50%) scale(0.9);
            }
        }

        @include max-breakpoint(480px) {
            .btn.btn-play {
                transform: translateX(-46%) scale(0.8);
            }
        }

        @include max-breakpoint(440px) {
            .btn.btn-play {
                transform: translateX(-41%) scale(0.7);
            }
            .content-hero {
                h1 {
                    font-size: 55px;
                }
                &--background {
                    height: 513px;
                }
                &::after {
                    top: 361px;
                }
                .content--movement {
                    height: calc(100% + 499px);
                }

                &.has-video {
                    .content-hero--background {
                        height: 529px;
                    }
                    &::after {
                        top: 377px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 573px;
                    }
                    &::after {
                        top: 421px;
                    }
                    .content--movement {
                        height: calc(100% + 499px);
                    }

                    &.has-video {
                        .content-hero--background {
                            height: 589px;
                        }
                        &::after {
                            top: 437px;
                        }
                        .content--movement {
                            height: calc(100% + 530px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint(360px) {
            .btn.btn-play {
                transform: translateX(-36%) scale(0.6);
            }
            .content-hero {
                h1 {
                    font-size: 48px;
                }
                h3 {
                    font-size: 26px;
                }
                &--background {
                    height: 484px;
                }
                &::after {
                    top: 332px;
                }
                .content--movement {
                    height: calc(100% + 499px);
                }

                &.has-video {
                    .content-hero--background {
                        height: 500px;
                    }
                    &::after {
                        top: 348px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 544px;
                    }
                    &::after {
                        top: 392px;
                    }
                    .content--movement {
                        height: calc(100% + 499px);
                    }

                    &.has-video {
                        .content-hero--background {
                            height: 559px;
                        }
                        &::after {
                            top: 407px;
                        }
                        .content--movement {
                            height: calc(100% + 530px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint(350px) {
            .content-hero {
                &.has-video {
                    .content-hero--background {
                        height: 515px;
                    }
                    &::after {
                        top: 363px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &.has-video {
                        .content-hero--background {
                            height: 574px;
                        }
                        &::after {
                            top: 422px;
                        }
                        .content--movement {
                            height: calc(100% + 530px);
                        }
                    }
                }
            }
        }

        .content--tagline {
            font-size: 65px !important;

            @include max-breakpoint(900px) {
                font-size: 62px !important;
            }

            @include max-breakpoint($screen-xs-max) {
                font-size: 46px !important;
            }
        }
    }

    .compatibility {
        &--header {
            .btn {
                line-height: 1.2;
                white-space: normal;
            }

            @include range-breakpoint($screen-sm, $screen-md) {
                h2 {
                    width: 365px;
                }
            }

            @include range-breakpoint($screen-md, $screen-lg) {
                h2 {
                    width: 480px;
                }
            }
        }
    }
}
