.error-content {
  background: #7dd9ff url('#{$image-path}/system/error-background.jpg') bottom center no-repeat;
  background-size: cover;
  height: calc(100vh - 414px);
  min-height: 280px;
  padding-top: 60px;
  padding-bottom: 160px;
  text-align: center;
}

.error-window {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
  font-family: "Lato", sans-serif;
  margin: 0 auto;
  max-width: 70%;
  padding: 30px;
  width: 450px;

  &--headline {
    color: #0069e1;
    font-size: 36px;
    font-weight: 300;
    margin: 0;
  }

  &--content {
    color: #000;
    font-weight: 400;
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 15px;
  }
}
