//
// Photo Gallery
////////////////////////////////////////////////////////////////////////////////

.photo-gallery {
    margin-top: 24px;
    padding: 0;

    li {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
        opacity: 1;
    }

    &.is-initialized {
        img {
            transition: opacity 0.3s ease-out;
        }
    }
}
