//
// Modals
////////////////////////////////////////////////////////////////////////////////

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent url('#{$image-path}/modal-background.png') repeat-x 0 0;
    z-index: 200;
    padding: 0 20px;

    h2 {
        color: $color-blue;
        font-weight: 300;
        font-size: 35px;
        margin-bottom: 20px;
        line-height: 1;

        @include max-breakpoint($screen-xs-max) {
            font-size: 25px;
            margin-bottom: 14px;
        }
    }

    p {
        font-weight: 400;
        font-size: 20px;
        margin-bottom: 20px;

        @include max-breakpoint($screen-xs-max) {
            font-size: 16px;
        }
    }

    &--video-wrapper {
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    &--video-container {
        display: block;
    }

    &--head-image {
        margin-top: 8px;
        margin-bottom: 20px;
        text-align: center;
    }

    &--wrapper {
        max-width: 755px;
        margin: 88px auto 0 auto;

        @include max-breakpoint($screen-xs-max) {
            margin-top: 20px;
            height: calc(100vh - 70px);
        }
    }

    &--header {
        @include clearfix();
        padding-bottom: 20px;

        li {
            margin-left: 10px;
        }

        @include max-breakpoint($screen-xs-max) {
            padding-bottom: 10px;
        }
    }

    &--close {
        @include sprite(-360px -111px)
        float: right;
        width: 45px;
        height: 45px;
        display: inline-block;
        padding: 0;
        border: 0;
        color: transparent;
        margin-top: 4px;

        @include max-breakpoint($screen-xs-max) {
            transform-origin: 100% 0;
            transform: scale(0.75);
        }
    }

    &--content {
        height: calc(100vh - 217px);
        overflow-y: auto;

        @include max-breakpoint($screen-xs-max) {
            height: calc(100vh - 160px);
        }

    }

    &-large {
        .modal--wrapper {
            max-width: 950px;
        }
    }

    &-social {
        .modal--content {
            @include max-breakpoint($screen-xs-max) {
                height: calc(100vh - 240px);
            }
        }
    }

    &-social,
    &-confirm {
        .btn {
            font-weight: 700;
            font-size: 16px;
            line-height: 1.2;
            padding: 8px 18px;
            white-space: normal;
            text-decoration: none;
            transition: all 0.4s ease-out;

            &:after,
            &:before {
                display: none;
            }
        }
    }

    &-scrollable {
        overflow-y: auto;

        .modal--bottom-button {
            margin-top: 20px;
        }

        .modal--content {
            height: auto;
            margin-bottom: 60px;

            h3 {
                color: $color-dark-grey;
                font-size: 18px;
                font-weight: 700;
                margin: 10px 0;

                span {
                    color: inherit;
                    font-size: inherit;
                    font-weight: inherit;    
                }
            }

            p {
                color: $color-dark-grey;
                font-size: 16px;
                font-weight: 400;
                margin: 10px 0;
            }

            ul {
                color: $color-dark-grey;
                padding-left: 1em;
                font-weight: 400;
            }

            li {
                color: $color-dark-grey;
                list-style-type: disc;
                font-size: 16px;
                font-weight: 400;
                margin: 5px 0 5px 1em;
            }
        }
    }
}
