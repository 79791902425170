//
// Buttons
////////////////////////////////////////////////////////////////////////////////

.btn {
    display: inline-block;
    border-radius: 20px;
    position: relative;
    font-family: $font-family-special;
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    text-transform: uppercase;
    background: #00acee;
    background: linear-gradient(to right,  #00acee 4%,#0087e6 33%,#00d1f6 93%);
    padding: 8px 54px 8px 18px;
    border: 2px #fff solid;
    text-align: left;
    white-space: nowrap;
    color: #fff;
    text-decoration: none;
    transition: all 0.4s ease-out;

    &:after {
        @include sprite(-95px 0);
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 48px;
        height: 40px;
        transform-origin: 50% 50%;
        transform: scale(1);
        transition: transform 0.2s ease-out;
    }

    &:active,
    &:hover {
        color: #ffc800;
        text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
    }

    &-large {
        font-size: 22px;
        padding: 13px 69px 13px 18px;
        border-radius: 30px;

        &:after {
            right: 2px;
            top: 8px;
            transform-origin: 100% 50%;
            transform: scale(1.25);
        }
    }

    &-sm-read-more {
        &:active::before,
        &:hover::before {
            opacity: 0.2;
        }

        &:before {
            background-color: #fff;
            border-radius: 20px;
            content: '';
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            transition: opacity 0.2s ease;
            width: 100%;
        }

        &:active::after,
        &:hover::after {
            transform: scale(1.1);
        }

        @include max-breakpoint($screen-xs-max) {
            &:before {
                border-radius: 35px;
            }

            &:active::after,
            &:hover::after {
                transform: scale(1.1);
            }
        }
    }

    // block variant
    &-block {
        display: block;
    }

    // play button vairant
    &-play {
        border-radius: 14px;
        font-size: 25px;
        background: #10daf8;
        background: linear-gradient(to right,  #10daf8 0%,#0085e6 100%);
        padding: 13px 25px 13px 63px;
        margin: 10px 15px 15px 36px;
        transform-origin: 0 50%;
        position: relative;

        &:before {
            @include sprite(0 0);
            content: '';
            position: absolute;
            left: -44px;
            top: -13px;
            width: 93px;
            height: 87px;
        }

        &:after {
            display: none;
        }

        @include min-breakpoint($screen-sm) {
            transform-origin: 0 0;
        }
    }

    &-watch-trailer {
        transition: background 0.2s ease, color 0.3s ease;

        &[data-video-id=""] {
            display: none;
        }

        &:before {
          z-index: 3;
          transform: scale(1);
          transition: transform 0.3s ease-out;
        }

        &:hover {
          .btn--overlay {
              opacity: 0.2;
          }
        }

        &:active::before,
        &:hover::before {
            transform: scale(1.1);
        }
    }

    &--overlay {
        background: #fff;
        border-radius: 14px;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity 0.2s ease;
        width: 100%;
    }

    &-appstore {
        padding: 0;
        color: transparent;
        font-size: 0;
        border: 0;
        height: 40px;
        border-radius: 0;
        position: relative;
        overflow: hidden;
        background: transparent;
        background-image: url($image-store-sprite);
        background-repeat: no-repeat;
        background-size: 271px auto;
        display: inline-block;

        &:before,
        &:after {
            display: none;
        }

        &[href=""] {
            display: none !important;

            @include max-breakpoint ($screen-xs-max) {
                visibility: hidden !important;
                display: inline-block !important;
            }
        }
    }

    &-appstore-apple {
        background-position: 0 0;
        width: 138px;
    }

    &-appstore-google {
        background-position: -138px 0;
        width: 133px;
    }

    &-region {
        padding: 8px 50px 8px 14px;
    }

    &-plain {
        padding: 8px 18px;

        &:after {
            display: none;
        }
    }

    // didn't have a style for this...
    &-primary {
        background: #00d1f6;
    }

    &-secondary {
        background: #0087e6;
    }

    @include max-breakpoint($screen-xs-max) {
        font-size: 22px;
        border-radius: 35px;
        padding: 10px 60px 10px 28px;

        &:after {
            top: 0;
            right: -6px;
            transform-origin: 100% 0;
            transform: scale(1.3);
        }

        &.btn-play {
            border-radius: 14px;
            font-size: 25px;
            background: #10daf8;
            background: linear-gradient(to right,  #10daf8 0%,#0085e6 100%);
            padding: 13px 25px 13px 63px;
            margin: 10px 15px 15px 36px;
            transform-origin: 0 50%;
            position: relative;
        }

        &-appstore {
            padding: 0;
            color: transparent;
            border: 0;
            height: 40px;
            border-radius: 0;
        }
    }

}
