//
// Config
////////////////////////////////////////////////////////////////////////////////


// fonts
$font-family-normal: 'Lato', sans-serif;
$font-family-special: 'Roboto Condensed', 'Helvetica', 'Arial', sans-serif;

// colors
$debug: rgba(0,222,0,0.2);

$color-white-trans: rgba(255,255,255,0.7);
$color-white-trans-light: rgba(255,255,255,0.333);
$color-black-trans: rgba(0,0,0,0.333);
$color-black-trans-dark: rgba(0,0,0,0.555);

$color-blue-dark: #1358a5;
$color-blue: #0069e1;
$color-blue-bg: #2288e4;
$color-blue-bg-trans: rgba(0,105,225,0.7);

$color-pokemon-blue: #356aaf;
$color-pokemon-yellow: #feca30;

$color-green-light: #44ab87;
$color-green: #00803f;
$color-green-blur: #589b81;
$color-green-bg: #319d54;
$color-green-bg-trans: rgba(0,128,63,0.7);

$color-orange: #e95800;
$color-orange-bg-trans: rgba(255,93,22,0.7);

$color-purple: #6d0cc8;
$color-purple-bg-trans: rgba(84,36,220,0.7);

$color-light-grey: #777;
$color-dark-grey: #333333;
$color-grey-medium: #595959;
$color-grey-bg-trans: rgba(51,51,51,0.7);

$color-accent: #4cd1ec;
$color-accent-dark: #31b0e1;

$color-error-background: rgba(255,0,0,0.1);
$color-error-border: red;
$color-error-text: #333;


// images
$image-path: '/static/assets/images';
$image-sprite: '/static/assets/images/sprite.png';
$image-flag-sprite: '/static/assets/images/flags-sprite.png';
$image-social-sprite: '/static/assets/images/social-sprite.png';
$image-store-sprite: '/static/assets/images/store-sprite.png';
$image-sprite-background-width: 414px;

// dimensions
$content-max-width: 1150px;
$gus-height-desktop: 62px;
$gus-height-mobile: 57px;
$gus-height-desktop-smartbanner: 140px;
$gus-height-mobile-smartbanner: 135px;

//used to control nav scaling in between full size and mobile. (Sticky nav)
$nav-small-breakpoint: 874px;
$nav-small-height-breakpoint: 864px;
$nav-pre-mobile-breakpoint: 805px;


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         30px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet:             (720px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet !default;

// Medium screen / desktop
$container-desktop:            (940px + $grid-gutter-width) !default;
//** For `$screen-md-min` and up.
$container-md:                 $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop:      (1140px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop !default;
