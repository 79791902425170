//
// Footer styles
////////////////////////////////////////////////////////////////////////////////

.footer {
    background: rgb(12,85,167);
    background: linear-gradient(to bottom,  rgb(12,85,167) 0%,rgb(49,231,248) 100%);
    border-top: 2px #fff solid;
    min-height: 280px;
    padding: 30px 0 20px 0;
    position: relative;

    p {
        margin-top: 8px;

        .icon-caret-right {
            position: relative;
            top: -1px;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    .no-bottom {
      @media screen and (max-width: $screen-xs-max) {
        margin-bottom: -8px;
      }
    }

    &--disclaimer {
        font-size: 12px;
        color: #fff;
        text-align: center;
        padding: 0 15px;
        text-shadow: 1px 1px 1px rgba(0,0,0,0.1);

        &:first-of-type {
            margin-top: 50px;

            @include min-breakpoint($screen-sm) {
                margin-top: 10px;
            }
        }
    }

    &--wrapper {
        @include bind-width();
        text-align: center;
        position: relative;
        z-index: 2;
    }

    &--column {
        @include inline-middle();
        padding: 0 32px;
    }

    &--row {
        margin: 0 -32px;
        padding: 14px 0;

        .footer--row {
            margin: 0 -12px;
            padding: 0;

            .footer--column {
                padding: 0 12px;
            }
        }
        .footer--aux-links {
            margin-bottom: 10px;
        }
        &.footer--row-align-bottom {
            .footer--column {
                vertical-align: bottom;
            }
        }

        &:first-child {
            padding-top: 0;
        }
    }

    &--social-box {
        font-size: 0;
        margin-left: 10px;

        ul {
            font-size: 0;
        }

        span {
            @include inline-middle();
            font-size: 15px;
        }

        li {
            margin-left: 10px;
        }

        &:first-child {
            margin-left: 0;
        }
    }

    &--movement {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }

    &--movement-inner {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background: transparent url('#{$image-path}/hexagon-fade-down.png') repeat-x 0 0;
    }

    .select-widget {
        vertical-align: middle;
        width: 200px;
    }

    .icon-caret-right-circle {
        top: -2px;
    }

    @include range-breakpoint ($screen-sm, 1127px) {
        &--row {
            .footer--row {
                margin-top: 20px;
            }
        }
    }

    @include max-breakpoint ($screen-xs-max) {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;

        .region-select {
            margin-bottom: 20px;
        }

        &--column {
            width: 100%;
            padding: 0;
            margin: 20px 0 0 0;

            &:first-child {
                margin-top: 0;
            }
        }
        &--row {
            width: 100%;
            padding: 0;
            margin: 44px 0 0 0;

            .footer--row {
                width: 100%;
                padding: 0;
                margin: 0;

                .footer--column {
                    width: 100%;
                    padding: 0;
                }
            }
        }
        &--social-box {
            width: calc(100% - 24px);
            margin: 20px auto 0 auto;
            text-align: center;
            max-width: 296px;

            span {
                display: block;
                font-weight: 700;
                margin-bottom: 12px;
                text-align: center;
            }
            ul {
                display: block;

                li {
                    /*margin-left: 22px;*/
                    width: 20%;
                    text-align: center;
                    margin: 0;

                    a {
                        color: transparent;
                    }

                    &:first-child {
                        margin: 0;
                    }
                }
            }

            &:first-child {
                margin-top: 20px;
            }
        }
    }
}
