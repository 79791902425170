//
// news list item
////////////////////////////////////////////////////////////////////////////////

.news-list-item {
    position: relative;

    h3 {
        font-size: 35px;
        line-height: 1;
        padding-right: 20px;
        text-align: left;
        font-weight: 500;

        a {
            color: inherit;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        @include max-breakpoint($screen-xs-max) {
            margin-bottom: 16px;
        }
    }

    p {
        font-size: 20px;
        font-weight: 700;
        width: 100%;
        margin-bottom: 12px;
        padding-right: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include max-breakpoint($screen-xs-max) {
            margin-bottom: 42px;
        }
    }

    &--image {
        float: right;
        padding-right: 15px;
        margin-right: 8.333%;
        width: 25%;
        text-align: center;;

        img {
            display: block;
            width: 100%;
            height: auto;
            margin: 0 auto;
        }

        @include max-breakpoint($screen-xs-max) {
            width: 100%;
            margin: 0 0 32px 0;
            float: none;
            text-align: left;
            padding: 0 15px;

            img {
                max-width: 360px;
            }
        }
    }

    &--new {
        background: #fff;
        color: $color-green-blur;
        position: absolute;
        top: -48px;
        left: 50%;
        padding: 6px 10px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 900;
        border-radius: 0 0 6px 6px;
        transform: translateX(-50%);

        @include max-breakpoint($screen-xs-max) {
            border-radius: 6px;
            position: static;
            margin-right: 10px;
            display: inline-block;
            vertical-align: middle;
            transform: none;
        }
    }

    &--image-horizontal {
        max-width: 286px;
    }

    &--image-vertical {
        max-width: 173px;
    }

    &--date-wrap {
        position: relative;
        display: inline-block;
        width: auto;
    }

    &--date {
        position: relative;
        display: inline-block;
        vertical-align: top;
        color: #fff;
        font-weight: 700;
        font-size: 26px;
        text-align: center;
        line-height: 1;
        padding-top: 2px;
        margin-top: 14px;

        strong {
            font-size: 38px;
            display: block;
            font-weight: 900;
        }

        &.news-list-item--date-day-first {
            padding-top: 38px;
            position: relative;

            strong {
                position: absolute;
                top: 1px;
                left: 0;
                width: 100%;
                text-align: center;
            }
        }

        @include max-breakpoint($screen-xs-max) {
            font-size: 26px;
            margin-bottom: 16px;
            margin-top: 0;

            strong {
                font-size: 26px;
                display: inline-block;
                vertical-align: top;
            }
            &.news-list-item--date-day-first {
                padding-top: 0;
                margin-top: 3px;
                position: relative;
                padding-left: 40px;

                strong {
                    position: absolute;
                    top: 1px;
                    left: 0;
                    width: 35px;
                    text-align: center;
                }
            }
        }
    }

    @include max-breakpoint($screen-xs-max) {
        padding-left: 16px;
        padding-right: 16px;
    }
}
