//
// Spanish
////////////////////////////////////////////////////////////////////////////////

html[lang="es"] {
    // appropriate app store language
    .btn-appstore-apple {
        background-position: 0 -280px;
    }
    .btn-appstore-google {
        background-position: -138px -280px;
    }

    // keep nav items on 2 lines max
    .navigation--wrapper {
        .navigation--purchases {
            a {
                font-size: 14px;
                margin-top: -14px;

                &:after {
                    margin-top: 10px;
                }
            }
        }
    }

    // social box w/ long label cleanup
    .footer--social-box {
        span {
            display: block;
            margin-bottom: 4px;
        }

        li:first-child {
            margin-left: 0;
        }
    }

    // homepage hero vertical sizing on mobile
    body[data-page="homepage"] {
        .content-hero h1 {
            font-size: 85px;
        }
        @include range-breakpoint($screen-sm, 1160px) {
            .content-hero h1 {
                font-size: 80px;
            }
            .btn.btn-play {
                transform: scale(1);
            }
            .phone-display h3 {
                font-size: 28px;
            }
        }
        @include range-breakpoint($screen-sm, 990px) {
            .content-hero h1 {
                font-size: 75px;
            }
            .btn.btn-play {
                transform: scale(0.9);
            }
            .phone-display h3 {
                font-size: 26px;
            }
        }
        @include range-breakpoint($screen-sm, 840px) {
            .content-hero h1 {
                font-size: 70px;
            }
            .btn.btn-play {
                transform: scale(0.8);
            }
            .phone-display h3 {
                font-size: 24px;
            }
            .content-hero--callout {
                font-size: 16px;
            }
        }

        @include max-breakpoint($screen-xs-max) {
            // reduce news header
            .content--header h2 {
                font-size: 16px;
            }
            .btn.btn-play {
                transform: translateX(-55%) scale(1);
            }
            .content-hero {
                h1 {
                    font-size: 55px;
                }
                &--background {
                    height: 459px;
                }
                &::after {
                    top: 307px;
                }
                .content--movement {
                    height: calc(100% + 499px);
                }

                &.has-video {
                    .content-hero--background {
                        height: 474px;
                    }
                    &::after {
                        top: 322px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 518px;
                    }
                    &::after {
                        top: 366px;
                    }
                    .content--movement {
                        height: calc(100% + 499px);
                    }

                    &.has-video {
                        .content-hero--background {
                            height: 534px;
                        }
                        &::after {
                            top: 382px;
                        }
                        .content--movement {
                            height: calc(100% + 530px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint(560px) {
            .btn.btn-play {
                transform: translateX(-51%) scale(0.95);
            }
        }

        @include max-breakpoint(490px) {
            .btn.btn-play {
                transform: translateX(-50%) scale(0.9);
            }
        }

        @include max-breakpoint(440px) {
            .btn.btn-play {
                transform: translateX(-48%) scale(0.85);
            }
        }

        @include max-breakpoint(403px) {
            .btn.btn-play {
                transform: translateX(-43.5%) scale(0.75);
            }
            .content-hero {
                &--background {
                    height: 513px;
                }
                &::after {
                    top: 361px;
                }
                .content--movement {
                    height: calc(100% + 499px);
                }

                &.has-video {
                    .content-hero--background {
                        height: 529px;
                    }
                    &::after {
                        top: 377px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 573px;
                    }
                    &::after {
                        top: 421px;
                    }
                    .content--movement {
                        height: calc(100% + 499px);
                    }

                    &.has-video {
                        .content-hero--background {
                            height: 589px;
                        }
                        &::after {
                            top: 437px;
                        }
                        .content--movement {
                            height: calc(100% + 530px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint(380px) {
            .btn.btn-play {
                transform: translateX(-38.5%) scale(0.66);
            }
        }

        @include max-breakpoint(350px) {
            .btn.btn-play {
                transform: translateX(-36.5%) scale(0.6);
            }
            .content-hero {
                h1 {
                    font-size: 48px;
                }
                h3 {
                    font-size: 28px;
                }
                .content-hero--background {
                    height: 487px;
                }
                &::after {
                    top: 335px;
                }
                &.has-video {
                    .content-hero--background {
                        height: 517px;
                    }
                    &::after {
                        top: 365px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &.has-video {
                        .content-hero--background {
                            height: 577px;
                        }
                        &::after {
                            top: 425px;
                        }
                    }
                }
            }
        }


        .content--tagline {
            font-size: 62px !important;

            @include max-breakpoint(1100px) {
                font-size: 60px !important;
            }

            @include max-breakpoint(980px) {
                font-size: 52px !important;
            }

            @include max-breakpoint(900px) {
                font-size: 44px !important;
            }

            @include max-breakpoint(900px) {
                font-size: 42px !important;
            }

            @include max-breakpoint($screen-xs) {
                font-size: 32px !important;
            }
        }

        // LOCALIZED IMAGE OVERWRITES
        .section-home-explore {
            .content--movement-side {
                width: 520px;
                height: 624px;
                background-image: url('#{$image-path}/sections/side-home-explore-es.png');
                left: 46%;
                top: auto;
                bottom: -60px;
                z-index: 2;
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-home-explore-mobile-es.jpg');
            }
        }
    }

    body[data-page="go-plus"] {
        .sect-goplus-main {
            h2 {
                @include max-breakpoint($screen-xs-max) {
                    font-size: 26px;
                }

                @include min-breakpoint($screen-md) {
                    font-size: 22px;
                }

                @include min-breakpoint($screen-lg) {
                    font-size: 26px;
                }
            }
        }

        // LOCALIZED IMAGE OVERWRITES
        .sect-goplus-alerts {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-goplus-alerts-es.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-goplus-alerts-mobile-es.jpg');
            }
        }
    }

    body[data-page="explore"] {
        // LOCALIZED IMAGE OVERWRITES
        .content.sect-explore-maps {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-maps-es.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-maps-mobile-es.jpg');
            }
        }

        .content.sect-explore-encounter {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-encounter-es.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-encounter-mobile-es.jpg');
            }
        }

        .content.sect-explore-pokestops {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-pokestops-es.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-pokestops-mobile-es.jpg');
            }
        }

        .content.sect-explore-bag {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-bag-es.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-bag-mobile-es.jpg');
            }
        }

        .content.sect-explore-trainer {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-trainer-es.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-trainer-mobile-es.jpg');
            }
        }

        .content.sect-explore-medals {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-medals-es.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-medals-mobile-es.jpg');
            }
        }
    }

    body[data-page="pokemon"] {
        // LOCALIZED IMAGE OVERWRITES
        .sect-pokemon-catch {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-pokemon-catch-es.png');
            }
        }

        .sect-pokemon-pokedex {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-pokemon-pokedex-es.png');
            }
        }

        .sect-pokemon-traits {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-pokemon-traits-es.png');
            }
        }

        .sect-pokemon-evolution {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-pokemon-evolution-es.png');
            }
        }

        .sect-pokemon-eggs {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-pokemon-eggs-es.png');
            }
        }

        @include max-breakpoint($screen-xs-max) {
            .sect-pokemon-catch {
                background-image: url('#{$image-path}/sections/content-pokemon-catch-mobile-es.jpg');
            }

            .sect-pokemon-pokedex {
                background-image: url('#{$image-path}/sections/content-pokemon-pokedex-mobile-es.jpg');
            }

            .sect-pokemon-traits {
                background-image: url('#{$image-path}/sections/content-pokemon-traits-mobile-es.jpg');
            }

            .sect-pokemon-evolution {
                background-image: url('#{$image-path}/sections/content-pokemon-evolution-mobile-es.jpg');
            }
            .sect-pokemon-eggs {
                background-image: url('#{$image-path}/sections/content-pokemon-eggs-mobile-es.jpg');
            }
        }
    }

    body[data-page="teams-gyms"] {
        // LOCALIZED IMAGE OVERWRITES
        .sect-teams-main {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-main-es.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-main-mobile-es.jpg');
            }
        }

        .sect-teams-gyms {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-gyms-es.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-gyms-mobile-es.jpg');
            }
        }

        .sect-teams-prestige {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-prestige-es.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-prestige-mobile-es.jpg');
            }
        }

        .sect-teams-training {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-training-es.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-training-mobile-es.jpg');
            }
        }

        .sect-teams-attacking {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-attacking-es.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-attacking-mobile-es.jpg');
            }
        }

        .sect-teams-defending {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-defending-es.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-defending-mobile-es.jpg');
            }
        }
    }

    body[data-page="iap"] {
        // LOCALIZED IMAGE OVERWRITES
        .sect-iap-main {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-iap-main-es.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-iap-main-mobile-es.jpg');
            }
        }
    }

    .compatibility {
        &--header {
            .btn {
                line-height: 1.2;
                white-space: normal;
            }
        }
    }
}
