//
// Home Page
////////////////////////////////////////////////////////////////////////////////////////////////

body[data-page="news"] {

    .loading-container {
        background-image: url('#{$image-path}/sections/news-background-even.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        &.is-error {
            .loading-container--button {
                display: none;
            }
        }

        &.is-minmal {
            .loading-container--button {
                display: none;
            }
        }
    }
}
