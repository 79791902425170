//
// Main styles
////////////////////////////////////////////////////////////////////////////////

.main {
    overflow: hidden;
    background-image: url("/static/assets/images/sections/news-background-even.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
