//
// Icons
////////////////////////////////////////////////////////////////////////////////

.icon {
    @include inline-middle();
    position: relative;

    &-before {
        margin-right: 14px;
    }

    &-after {
        margin-left: 8px;
    }

    // section icons
    &-news {
        @include sprite(0 -150px);
        width: 26px;
        height: 21px;
        top: -4px;
    }

    &-search {
        @include sprite(-48px -150px);
        width: 29px;
        height: 29px;
    }

    &-goplus {
        @include sprite(-26px -150px);
        width: 22px;
        height: 31px;
    }

    // ui elements
    &-more {
        @include sprite(-360px -90px);
        width: 14px;
        height: 14px;
    }

    // >
    &-caret-right {
        @include sprite(-395px -90px);
        width: 6px;
        height: 10px;
    }

    // >
    &-caret-right-circle {
        @include sprite(-374px -90px);
        width: 21px;
        height: 21px;
        margin-left: 7px;
    }

    &-tip {
        @include sprite(-223px 0);
        width: 36px;
        height: 36px;
    }

    &-tip-dark {
        @include sprite(-187px 0);
        width: 36px;
        height: 36px;
    }
}
