//
// Region list in modal
////////////////////////////////////////////////////////////////////////////////

.region-list {
    text-align: center;

    &--heading {
        font-size: 35px;
        font-weight: 300;
        color: $color-blue;
        margin-bottom: 36px;
    }

    &--list {
        font-size: 0;

        li {
            margin: 6px;
            display: inline-block;

            @include max-breakpoint($screen-xs-max) {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    @include max-breakpoint($screen-xs-max) {
        padding-bottom: 80px;
    }
}


body.is-ios {
    
}
