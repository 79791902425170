//
// Gradient text
////////////////////////////////////////////////////////////////////////////////

.gradient-text {
    &--canvas {
        position: absolute;
        z-index: 0;
    }

    &.is-applied {
        span {
            position: relative;
            z-index: 2;
        }
    }
}
