//
// IAP list
////////////////////////////////////////////////////////////////////////////////

.iap-list {
    li {
        margin-bottom: 32px;
    }

    .box {
        background-repeat: no-repeat;
    }

    &--lucky-eggs {
        .box {
            background-image: url('#{$image-path}/iap/lucky-eggs.png');
            background-position: 46px center;

            @include max-breakpoint($screen-sm-max) {
                padding-top: 157px;
                background-position: center 20px;
            }
        }
    }
    &--incense {
        .box {
            background-image: url('#{$image-path}/iap/incense.png');
            background-position: 30px center;

            @include max-breakpoint($screen-sm-max) {
                padding-top: 186px;
                background-position: center 20px;
            }
        }
    }
    &--incubator {
        .box {
            background-image: url('#{$image-path}/iap/incubator.png');
            background-position: 48px center;

            @include max-breakpoint($screen-sm-max) {
                padding-top: 191px;
                background-position: center 20px;
            }
        }
    }
    &--discs {
        .box {
            background-image: url('#{$image-path}/iap/discs.png');
            background-position: 40px center;

            @include max-breakpoint($screen-sm-max) {
                padding-top: 171px;
                background-position: center 20px;
            }
        }
    }
    &--bag-upgrade {
        .box {
            background-image: url('#{$image-path}/iap/bag-upgrade.png');
            background-position: 34px center;

            @include max-breakpoint($screen-sm-max) {
                padding-top: 185px;
                background-position: center 20px;
            }
        }
    }
    &--ball-pack {
        .box {
            background-image: url('#{$image-path}/iap/ball-pack.png');
            background-position: 36px center;

            @include max-breakpoint($screen-sm-max) {
                padding-top: 153px;
                background-position: center 20px;
            }
        }
    }
    &--storage-upgrade {
        .box {
            background-image: url('#{$image-path}/iap/storage-upgrade.png');
            background-position: 24px center;

            @include max-breakpoint($screen-sm-max) {
                padding-top: 133px;
                background-position: center 20px;
            }
        }
    }

    @include max-breakpoint($screen-sm-max) {
        .box h2 {
            text-align: center;
            font-size: 25px;
            line-height: 1.2;
            margin-bottom: 12px;
        }
    }
}
