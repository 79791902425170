//
// Navigation styles
////////////////////////////////////////////////////////////////////////////////


.navigation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 154px;
    z-index: 1;
    overflow: hidden;

    &:before,
    &:after {
        content: '';
        display: block;
        width: calc(50% - 234px);
        height: 150px;
        position: absolute;
        top: 0;
        z-index: 1;
        background-repeat: no-repeat;
        background-size: 965px 118px;
    }

    &:before {
        left: 0;
        background-image: url('#{$image-path}/navigation-background-left.png');
        background-position: right top;
    }

    &:after {
        right: 0;
        background-image: url('#{$image-path}/navigation-background-right.png');
        background-position: left top;
    }

    &--wrapper {
        @include bind-width();
        text-align: center;
        position: relative;
        z-index: 2;

        ul {
            font-size: 0;

            li {
                display: inline-block;
                vertical-align: bottom;
                position: relative;

                &:nth-child(3) {
                    margin-right: 160px;
                }

                &:nth-child(4) {
                    margin-left: 159px;
                }
            }
        }


        a {
            display: block;
            text-transform: uppercase;
            color: #fff;
            width: 100px;
            text-align: center;
            text-decoration: none;
            font-size: 18px;
            line-height: 1;

            &:after {
                content: '';
                display: block !important;
                width: 60px;
                height: 60px;
                margin: 15px auto 0 auto;
                transition: transform 0.3s ease-out;

            }

            &:active::after,
            &:hover::after {
                /*transform: scale(1.2);*/
                transform-origin: center;
                animation: bounce-scale .5s ease-out;
            }
        }

        ul li.is-disabled {
            a {
                cursor: default;
                color: rgba(255,255,255,0.6);
                font-weight: 400 !important;

                &:after {
                    opacity: 0.6;
                }

                /*&:before {
                    content: attr(title);
                    position: absolute;
                    bottom: 10px;
                    left: 0;
                    transform: rotate(-45deg);
                    color: #fff;
                }*/
            }
        }
    }

    &--plus {
        //top: 37px;
        bottom: -34px;

        a:after {
            @include sprite(0 -90px);
        }

        a:active::after,
        a:hover::after {
            @include sprite(0 -308px);
        }

        &.is-disabled {
            a:active::after,
            a:hover::after {
                animation: none;
                @include sprite(0 -90px);
            }
        }
    }

    &--explore {
        bottom: -1px;

        a:after {
            @include sprite(-60px -90px);
        }

        a:active::after,
        a:hover::after {
            @include sprite(-60px -308px);
        }

        &.is-disabled {
            a:active::after,
            a:hover::after {
                animation: none;
                @include sprite(-60px -90px);
            }
        }
    }

    &--pokemon {
        bottom: -34px;

        a:after {
            @include sprite(-120px -90px);
        }

        a:active::after,
        a:hover::after {
            @include sprite(-120px -308px);
        }

        &.is-disabled {
            a:active::after,
            a:hover::after {
                animation: none;
                @include sprite(-120px -90px);
            }
        }
    }

    &--teams {
        bottom: -34px;

        a:after {
            @include sprite(-180px -90px);
        }

        a:active::after,
        a:hover::after {
            @include sprite(-180px -308px);
        }

        &.is-disabled {
            a:active::after,
            a:hover::after {
                animation: none;
                @include sprite(-180px -90px);
            }
        }
    }

    &--photos {
        bottom: -1px;

        a:after {
            @include sprite(-240px -90px);
        }

        a:active::after,
        a:hover::after {
            @include sprite(-240px -308px);
        }

        &.is-disabled {
            a:active::after,
            a:hover::after {
                animation: none;
                @include sprite(-240px -90px);
            }
        }
    }

    &--purchases {
        bottom: -34px;

        a:after {
            @include sprite(-300px -90px);
        }

        a:active::after,
        a:hover::after {
            @include sprite(-300px -308px);
        }

        &.is-disabled {
            a:active::after,
            a:hover::after {
                animation: none;
                @include sprite(-300px -90px);
            }
        }
    }

    &--background {
        display: none;
    }

    //
    // Breakpoints
    //

    @include range-breakpoint(865px, $screen-md) {
        &:before,
        &:after {
            width: calc(50% - 204px);
        }

        &--wrapper {
            ul {

                li {
                    &:nth-child(3) {
                        margin-right: 130px;
                    }

                    &:nth-child(4) {
                        margin-left: 129px;
                    }
                }
            }
            a {
                font-size: 16px;
                line-height: 1.125;
            }
        }
    }

    @media screen and (min-width:$nav-small-height-breakpoint) and (max-width:$nav-small-breakpoint) {
      &--wrapper {
        ul {
          margin-top: -8px;
        }
      }
    }

    @include range-breakpoint($screen-sm, $nav-small-breakpoint) {

        &:before,
        &:after {
            width: calc(50% - 174px);
            background-size: 724px auto;
        }

        &--wrapper {

            ul {

                li {
                    &:nth-child(n+5) {
                        margin-left: 0px;
                    }
                    &:nth-child(-n+2) {
                        margin-right: 0px;
                    }
                    &:nth-child(3) {
                        margin-right: 123px;
                    }

                    &:nth-child(4) {
                        margin-left: 114px;
                    }
                }
            }
            a {
                font-size: 14px;
                width: 75px;

                &:after {
                    width: 45px;
                    height: 45px;
                    margin-top: 10px;
                }
            }
        }

        // sprite scaled to 0.75
        .navigation--plus {
            bottom: -28px;

            a:after {
                @include sprite(0 -67.25px);
                background-size: 310.5px auto;
            }

            a:active::after,
            a:hover::after {
              @include sprite(0 -231px);
              background-size: 310.5px auto;
            }

            &.is-disabled {
                a:after,
                a:active::after,
                a:hover::after {
                    @include sprite(0 -67.25px);
                    background-size: 310.5px auto;
                }
            }
        }

        .navigation--explore {
            bottom: -3px;

            a:after {
                @include sprite(-45px -67.5px);
                background-size: 310.5px auto;
            }

            a:active::after,
            a:hover::after {
              @include sprite(-45px -231px);
              background-size: 310.5px auto;
            }

            &.is-disabled {
                a:after,
                a:active::after,
                a:hover::after {
                    @include sprite(-45px -67.5px);
                    background-size: 310.5px auto;
                }
            }
        }

        .navigation--pokemon {
            bottom: -28px;

            a:after {
                @include sprite(-90px -67.5px);
                background-size: 310.5px auto;
            }

            a:active::after,
            a:hover::after {
              @include sprite(-90px -231px);
              background-size: 310.5px auto;
            }

            &.is-disabled {
                a:after,
                a:active::after,
                a:hover::after {
                    @include sprite(-90px -67.5px);
                    background-size: 310.5px auto;
                }
            }
        }

        .navigation--teams {
            bottom: -28px;

            a:after {
                @include sprite(-135px -67.5px);
                background-size: 310.5px auto;
            }

            a:active::after,
            a:hover::after {
              @include sprite(-135px -231px);
              background-size: 310.5px auto;
            }

            &.is-disabled {
                a:after,
                a:active::after,
                a:hover::after {
                    @include sprite(-135px -67.5px);
                    background-size: 310.5px auto;
                }
            }
        }

        .navigation--photos {
            bottom: -3px;

            a:after {
                @include sprite(-180px -67.5px);
                background-size: 310.5px auto;
            }

            a:active::after,
            a:hover::after {
              @include sprite(-180px -231px);
              background-size: 310.5px auto;
            }

            &.is-disabled {

                a:after,
                a:active::after,
                a:hover::after {
                    @include sprite(-180px -67.5px);
                    background-size: 310.5px auto;
                }
            }
        }

        .navigation--purchases {
            bottom: -28px;

            a:after {
                @include sprite(-225px -67.5px);
                background-size: 311px auto;
            }

            a:active::after,
            a:hover::after {
              @include sprite(-225px -231px);
              background-size: 311px auto;
            }

            &.is-disabled {
                a:after,
                a:active::after,
                a:hover::after {
                    @include sprite(-225px -67.5px);
                    background-size: 311px auto;
                }
            }
        }
    }

    @include max-breakpoint ($screen-xs-max) {
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 1;


        &:before,
        &:after {
            display: none;
        }

        &--background {
            position: absolute;
            top: -30px;
            left: -30px;
            display: block;
            width: calc(100% + 60px);
            height: calc(100% + 60px);
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            background-attachment: fixed;
            z-index: 0;
            filter: blur(10px);
            opacity: 0.7;

            &:before,
            &:after {
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                height: 40%;
                z-index: 0;
                /*filter: blur(10px);*/
                display: none;
            }

            &:before {
                top: 0;
                background: linear-gradient(to bottom, rgba(86,200,163,1) 0%,rgba(158,221,134,0.5) 50%,rgba(155,255,123,0) 100%);
            }

            &:after {
                bottom: 0;
                background: linear-gradient(to bottom, rgba(155,255,123,0) 0%,rgba(158,221,134,0.5) 50%,rgba(86,200,163,1) 100%);
            }
        }

        // Toggle state changes based on body class (see below)
        &--toggle {
            @include sprite(-95px -40px);
            width: 44px;
            height: 44px;
            border: 0;
            padding: 0;
            position: absolute;
            top: 20px;
            right: 20px;
            color: transparent;
            z-index: 300;
            opacity: 1;
            transition: opacity 0.3s ease-out;
            outline: 0 !important;
        }

        // animations based on body class (see below)
        &--wrapper {
            @include bind-width();
            text-align: center;
            position: absolute;
            left: calc(100% + 50px);
            top: 0;
            width: 100vw;
            height: calc(100vh - #{$gus-height-mobile});
            z-index: 200;
            transition: left 0.3s ease-out;
            background: linear-gradient(to bottom,  rgba(4,218,190,1) 0%,rgba(155,255,123,1) 55%,rgba(155,255,123,1) 100%);
            overflow-y: auto;
            overflow-x: hidden;

            &:before {
                background: transparent url('#{$image-path}/navigation-background-top-mobile.png') no-repeat 0 0;
                background-size: 925px 214px;
                content: '';
                display: block;
                width: 925px;
                height: 107px;
                position: absolute;
                top: 0;
                left: -208px;
                z-index: 100;
            }

            ul {
                position: relative;
                display: block;
                margin: 0 auto;
                padding-top: 125px;
                width: 320px;
                z-index: 3;

                li {
                    z-index: 1;

                    &:nth-child(3) {
                        margin-right: 0;
                    }
                    &:nth-child(4) {
                        margin-left: 0;
                    }
                    &:nth-child(-n+3) {
                        margin-bottom: 70px;

                        @include max-breakpoint(320px) {
                            margin-bottom: 50px;
                        }
                    }
                }

                &:before,
                &:after {
                    content: '';
                    width: 100vw;
                    height: 43px;
                    position: absolute;
                    background-size: 450px 43px;
                    background-repeat: no-repeat;
                    opacity: 0;
                    transition: opacity 0.3s ease-out;
                    z-index: 0;
                }

                &:before {
                    top: 203px;
                    right: 85px;
                    background-position: right top;
                    background-image: url('#{$image-path}/navigation-background-left-mobile.png');
                }

                &:after {
                    top: 386px;
                    left: 85px;
                    background-position: left top;
                    background-image: url('#{$image-path}/navigation-background-right-mobile.png');
                }
            }


            a {

            }
        }
    }

    @include max-breakpoint (359px) {
        .navigation--wrapper {
            ul {
                padding-top: 80px;

                &:before {
                    top: 158px;
                }

                &:after {
                    top: 341px;
                }
            }
        }
    }


    @include max-breakpoint(320px) {
        .navigation--wrapper {
            ul {
                &:after {
                    top: 321px;
                }
            }
        }
    }
}


body {
    // navigation open
    &.is-open-navigation,
    &.is-animating-navigation {
        .navigation {
            z-index: 1000;
            overflow: visible;
        }

        .content {
            z-index: 0;
        }
    }

    &.is-open-navigation {
        .navigation--wrapper {
            left: 0;
            position: fixed;
            top: $gus-height-mobile;

            ul {
                &:before,
                &:after {
                    opacity: 1;
                }
            }

            @include max-breakpoint(359px) {
                &:before {
                    left: -199px;
                }
            }
        }

        .navigation--background {
            position: fixed;
        }

        .navigation--toggle {
            @include sprite(-143px 0);
        }
    }

    &.is-animating-navigation {
        .navigation--wrapper {
            position: fixed;
            top: $gus-height-mobile;

            &:before {
                background-position: 0 -107px;
            }
        }

        .navigation--toggle {
            opacity: 0;
        }
    }

    &.is-animating-navigation-open {
        .navigation--wrapper {
            left: 0;
        }
    }

    &.is-animating-navigation-close {
        .navigation--wrapper {
            left: calc(100% + 50px);

            ul {
                &:before,
                &:after {
                    display: none;
                }
            }
        }
    }
}


//
// Sticky header/nav
//

@include min-breakpoint($screen-sm) {
    .header.is-sticky {
        background: transparent url('#{$image-path}/header-background-sticky.png') repeat-x center top;

        .navigation {
            height: 87px !important;
            border-bottom: 2px #fff solid;

            &:before,
            &:after {
                width: 305px;
                height: 47px;
                position: absolute;
                background-size: 305px 47px;
            }

            &:before {
                left: auto;
                right: calc(50% + 171px);
                background-image: url('#{$image-path}/navigation-background-left-sticky.png');
                background-position: right top;
            }

            &:after {
                right: auto;
                left: calc(50% + 171px);
                background-image: url('#{$image-path}/navigation-background-right-sticky.png');
                background-position: left top;
            }

            &--wrapper {
                ul li {
                    margin: 0;

                    &:nth-child(-n+2) {
                        margin-right: -1px;
                    }

                    &:nth-child(n+5) {
                        margin-right: -1px;
                    }

                    &:nth-child(3) {
                        margin-right: 98px;
                    }

                    &:nth-child(4) {
                        margin-left: 98px;
                        margin-right: -1px;
                    }

                    //nav not positioning correctly at small sizes without this
                    @media screen and (max-width:$nav-pre-mobile-breakpoint) {
                      &:nth-child(1) {
                        left: -50px;
                      }

                      &:nth-child(2) {
                        left: -30px;
                      }

                      &:nth-child(3) {
                        left: -10px;
                      }

                      &:nth-child(4) {
                        left: 10px;
                      }

                      &:nth-child(5) {
                        left: 30px;
                      }

                      &:nth-child(6) {
                        left: 50px;
                      }
                    }
                }

                a {
                    //margin-top: 12px;
                    width: 100px;
                    font-size: 0 !important;
                    color: transparent;

                    @media screen and (max-width:$nav-small-breakpoint) {
                      margin-top: 12px;
                    }

                    @media screen and (max-width:$nav-pre-mobile-breakpoint) {
                      width: 80px;
                    }

                    &:after {
                        @include accelerate();
                        margin: 0 !important;
                        transform: scale(0.8);
                        transform-origin: 50% 100%;
                        display: inline-block !important;

                        @media screen and (max-width:$nav-small-breakpoint) {
                          transform: scale(1.05);
                        }
                    }

                    &:active::after,
                    &:hover::after {
                        transform: scale(1);
                        /*transition: transform 0.3s ease-out;*/
                        animation: sm-bounce-scale .5s ease-out;

                        @media screen and (max-width:$nav-small-breakpoint) {
                            /*transform: scale(1.2);
                            transition: transform 0.3s ease-out;*/
                            animation: sm-bounce-scale .5s ease-out;
                        }
                    }
                }
            }

            &--plus {
                bottom: -10px;
            }

            &--explore {
                bottom: -10px;
            }

            &--pokemon {
                bottom: -10px;
            }

            &--teams {
                bottom: -10px;
            }

            &--photos {
                bottom: -10px;
            }

            &--purchases {
                bottom: -10px;
            }
        }
    }
}
