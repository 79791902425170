//
// compatibility
////////////////////////////////////////////////////////////////////////////////

$gradient-light-blue: #77eaff;
$gradient-dark-blue: #39bdf0;
$blue-headline: #143f70;

.compatibility {
    background: #77eaff;
    background: linear-gradient(to bottom, $gradient-light-blue 0%, $gradient-dark-blue 100%);
    padding: 25px 0;

    &--header {
        h2 {
            font-size: 19px;
            line-height: 1.2;
            margin-bottom: 20px;
        }

        .btn {
            @include max-breakpoint($screen-sm-max) {
                font-size: 12px;
                margin-bottom: 20px;
            }
        }

        @include min-breakpoint($screen-sm) {
            @include clearfix();

            h2 {
                float: left;
                width: 425px;
            }

            .btn {
                float: right;
            }
        }

        @include min-breakpoint($screen-md) {
            h2 {
              width: 560px;
            }
        }
    }

    &--list {
        &-item {
          h4 {
              color: $blue-headline;
              font-size: 16px;
              text-transform: none;
          }

          p {
              color: $color-dark-grey;
              font-size: 13px;
          }

          &:last-child {
              p {
                  margin-bottom: 0;
              }
          }

          @include min-breakpoint($screen-sm) {
              float: left;
              width: 50%;

              &:first-child {
                  padding-right: 20px;
              }

              p {
                  margin-bottom: 35px;
              }
          }
        }
    }
}
