//
// Store list (in order modal)
////////////////////////////////////////////////////////////////////////////////

.store-list {
    margin: 0;
    padding: 20px 0;
    font-size: 0;
    text-align: center;

    &--item {
        display: block;
        width: 100%;
        margin-top: 32px;

        &:first-child {
            margin-top: 0;
        }
    }

    &--link {
        font-size: 0;
        color: transparent;
    }

    img {
        display: block;
        max-width: 280px;
        max-height: 80px;
        width: 100%;
        font-size: 28px;
        color: $color-dark-grey;
        font-weight: 400;
        margin: 0 auto;

        &:hover {
            text-decoration: underline;
        }
    }
}
