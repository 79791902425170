//
// logos
////////////////////////////////////////////////////////////////////////////////

.logo {
    color: transparent;
    vertical-align: middle;

    &-pokemon-company {
        @include sprite(0 -184px);
        width: 211px;
        height: 23px;
    }

    &-niantic {
        @include sprite(-341px -184px);
        width: 71px;
        height: 124px;
    }

    &-nintendo {
        @include sprite(-211px -184px);
        width: 130px;
        height: 32px;
    }

    &-esrb-kids {
        @include sprite(-160px -216px);
        width: 68px;
        height: 92px;
    }

    &-esrb-rating {
        @include sprite(0 -216px);
        width: 160px;
        height: 75px;
    }
}
