//
// English
////////////////////////////////////////////////////////////////////////////////

html[lang="en"] {
    body[data-page="homepage"] {

        @include range-breakpoint($screen-sm, 890px) {
            .content-hero h1 {
                font-size: 95px;
            }
            .btn.btn-play {
                transform: scale(0.9);
            }
            .phone-display h3 {
                font-size: 28px;
            }
            .content-hero--callout {
                /*font-size: 16px;*/
            }
        }

        @include range-breakpoint($screen-sm, 800px) {
            .content-hero h1 {
                font-size: 85px;
            }
            .btn.btn-play {
                transform: scale(0.8);
            }
            .phone-display h3 {
                font-size: 26px;
            }
            .content-hero--callout {
                font-size: 16px;
            }
        }

        @include max-breakpoint($screen-xs-max) {
            .btn.btn-play {
                transform: translateX(-51%) scale(1);
            }
            .content-hero {
                &--background {
                    height: 480px;
                }
                &::after {
                    top: 328px;
                }
                .content--movement {
                    height: calc(100% + 499px);
                }

                &.has-video {
                    .content-hero--background {
                        height: 496px;
                    }
                    &::after {
                        top: 344px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 540px;
                    }
                    &::after {
                        top: 388px;
                    }
                    .content--movement {
                        height: calc(100% + 499px);
                    }

                    &.has-video {
                        .content-hero--background {
                            height: 556px;
                        }
                        &::after {
                            top: 404px;
                        }
                        .content--movement {
                            height: calc(100% + 530px);
                        }
                    }
                }
            }
        }
        @include max-breakpoint(500px) {
            .btn.btn-play {
                transform: translateX(-50%) scale(0.90);
            }
            .content-hero {
                &.has-video {
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }
            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &.has-video {
                        .content-hero--background {
                            height: 556px;
                        }
                        &::after {
                            top: 404px;
                        }
                        .content--movement {
                            height: calc(100% + 530px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint(444px) {
            .btn.btn-play {
                transform: translateX(-48%) scale(0.85);
            }
        }

        @include max-breakpoint(420px) {
            .btn.btn-play {
                transform: translateX(-43%) scale(0.75);
            }
        }

        @include max-breakpoint(370px) {
            .btn.btn-play {
                transform: translateX(-41%) scale(0.7);
            }
        }

        @include max-breakpoint(350px) {
            .btn.btn-play {
                transform: translateX(-37%) scale(0.62);
            }
            .content-hero {
                &.has-video {
                    .content-hero--background {
                        height: 505px;
                    }
                    &::after {
                        top: 353px;
                    }
                    .content--movement {
                        height: calc(100% + 536px);
                    }
                }
            }
            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &.has-video {
                        .content-hero--background {
                            height: 565px;
                        }
                        &::after {
                            top: 413px;
                        }
                        .content--movement {
                            height: calc(100% + 536px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint(333px) {
            .content-hero {
                &--background {
                    height: 472px;
                }
                &::after {
                    top: 320px;
                }
                .content--movement {
                    /*height: calc(100% + 442px);*/
                }
                &.has-video {
                    .content-hero--background {
                        height: 498px;
                    }
                    &::after {
                        top: 346px;
                    }
                    .content--movement {
                        height: calc(100% + 535px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 532px;
                    }
                    &::after {
                        top: 380px;
                    }
                    .content--movement {
                        /*height: calc(100% + 442px);*/
                    }

                    &.has-video {
                        .content-hero--background {
                            height: 557px;
                        }
                        &::after {
                            top: 405px;
                        }
                        .content--movement {
                            height: calc(100% + 535px);
                        }
                    }
                }
            }
        }
    }

    body[data-page="teams-gyms"] {
        .sect-teams-main {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-main-en.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-main-mobile-en.jpg');
            }
        }
    }
}
