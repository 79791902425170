//
// Italian
////////////////////////////////////////////////////////////////////////////////

html[lang="it"] {
    // appropriate app store language
    .btn-appstore-apple {
        background-position: 0 -120px;
    }

    .btn-appstore-google {
        background-position: -138px -120px;
    }

    // keep nav items on 2 lines max
    .navigation--purchases {
        a {
            font-size: 15px;
        }
    }

    @include range-breakpoint($screen-sm, $nav-small-breakpoint) {
        .navigation--teams {
            a {
                font-size: 12px;
            }
        }

        .navigation--purchases {
            a {

                font-size: 12px;
            }

            a:after {
                margin-top: 5px;
                position: relative;
                left: -1px;
            }
        }
    }

    // social box w/ long label cleanup
    .footer--social-box {
        span {
            display: block;
            margin-bottom: 4px;
        }

        li:first-child {
            margin-left: 0;
        }
    }

    @include max-breakpoint(560px) {
        .btn.btn-play {
            /*transform: translateX(-46%) scale(0.85);*/
        }
    }

    // homepage hero vertical sizing on mobile
    body[data-page="homepage"] {
        .content--tagline {
            font-size: 48px !important;

            @include max-breakpoint(900px) {
                font-size: 42px !important;
            }

            @include max-breakpoint($screen-xs-max) {
                font-size: 24px !important;
            }
        }

        @include range-breakpoint($screen-sm, 960px) {
            .content-hero h1 {
                font-size: 90px;
            }
            .btn.btn-play {
                transform: scale(0.9);
            }
        }

        @include range-breakpoint($screen-sm, 920px) {
            .content-hero h1 {
                font-size: 90px;
            }
            .btn.btn-play {
                transform: scale(0.9);
            }
            .phone-display h3 {
                font-size: 24px;
            }
        }

        @include range-breakpoint($screen-sm, 900px) {
            .content-hero h1 {
                font-size: 80px;
            }
            .btn.btn-play {
                transform: scale(0.85);
            }
            .phone-display h3 {
                font-size: 24px;
            }
        }

        @include max-breakpoint($screen-xs-max) {
            // reduce news header
            .content--header h2 {
                font-size: 18px;
            }
            .btn.btn-play {
                transform: translateX(-54%) scale(1);
            }
            .content-hero {
                &--background {
                    height: 414px;
                }
                &::after {
                    top: 262px;
                }
                .content--movement {
                    height: calc(100% + 499px);
                }

                &.has-video {
                    .content-hero--background {
                        height: 430px;
                    }
                    &::after {
                        top: 278px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 474px;
                    }
                    &::after {
                        top: 322px;
                    }
                    .content--movement {
                        height: calc(100% + 499px);
                    }

                    &.has-video {
                        .content-hero--background {
                            height: 490px;
                        }
                        &::after {
                            top: 338px;
                        }
                        .content--movement {
                            height: calc(100% + 530px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint(511px) {
            .btn.btn-play {
                transform: translateX(-50%) scale(0.9);
            }
            .content-hero {
                &--background {
                    height: 480px;
                }
                &::after {
                    top: 328px;
                }
                .content--movement {
                    height: calc(100% + 499px);
                }

                &.has-video {
                    .content-hero--background {
                        height: 496px;
                    }
                    &::after {
                        top: 344px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 540px;
                    }
                    &::after {
                        top: 388px;
                    }
                    .content--movement {
                        height: calc(100% + 499px);
                    }

                    &.has-video {
                        .content-hero--background {
                            height: 556px;
                        }
                        &::after {
                            top: 404px;
                        }
                        .content--movement {
                            height: calc(100% + 530px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint(460px) {
            .btn.btn-play {
                transform: translateX(-45%) scale(0.8);
            }
        }

        @include max-breakpoint(410px) {
            .btn.btn-play {
                transform: translateX(-40%) scale(0.7);
            }
        }

        @include max-breakpoint(371px) {
            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 585px;
                    }
                    &::after {
                        top: 433px;
                    }
                    .content--movement {
                        height: calc(100% + 499px);
                    }

                    &.has-video {
                        .content-hero--background {
                            height: 600px;
                        }
                        &::after {
                            top: 448px;
                        }
                        .content--movement {
                            height: calc(100% + 530px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint(350px) {
            .btn.btn-play {
                transform: translateX(-35%) scale(0.6);
            }
            .content-hero {
                &.has-video {
                    .content-hero--background {
                        height: 511px;
                    }
                    &::after {
                        top: 359px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }
            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &.has-video {
                        .content-hero--background {
                            height: 616px;
                        }
                        &::after {
                            top: 464px;
                        }
                        .content--movement {
                            height: calc(100% + 530px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint(334px) {
            .content-hero {
                h1 {
                    font-size: 62px;
                }
                &--background {
                    height: 517px;
                }
                &::after {
                    top: 365px;
                }
                .content--movement {
                    height: calc(100% + 499px);
                }

                &.has-video {
                    .content-hero--background {
                        height: 548px;
                    }
                    &::after {
                        top: 396px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 577px;
                    }
                    &::after {
                        top: 425px;
                    }
                    .content--movement {
                        /*height: calc(100% + 499px);*/
                    }

                    &.has-video {
                        .content-hero--background {
                            height: 608px;
                        }
                        &::after {
                            top: 456px;
                        }
                        .content--movement {
                            height: calc(100% + 530px);
                        }
                    }
                }
            }
        }

        // LOCALIZED IMAGE OVERWRITES
        .section-home-explore {
            .content--movement-side {
                width: 520px;
                height: 624px;
                background-image: url('#{$image-path}/sections/side-home-explore-it.png');
                left: 46%;
                top: auto;
                bottom: -60px;
                z-index: 2;
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-home-explore-mobile-it.jpg');
            }
        }
    }

    body[data-page="go-plus"] {
        .content-side-text h2 {
            @include max-breakpoint($screen-xs-max) {
                font-size: 25px;
            }

            @include range-breakpoint($screen-sm, $screen-lg) {
                font-size: 24px;
            }
        }

        // LOCALIZED IMAGE OVERWRITES
        .sect-goplus-alerts {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-goplus-alerts-it.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-goplus-alerts-mobile-it.jpg');
            }
        }
    }

    body[data-page="explore"] {
        @include max-breakpoint($screen-xs-max) {
            .sect-explore-main h2 {
                font-size: 24px;
            }
        }

        // LOCALIZED IMAGE OVERWRITES
        .content.sect-explore-maps {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-maps-it.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-maps-mobile-it.jpg');
            }
        }

        .content.sect-explore-encounter {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-encounter-it.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-encounter-mobile-it.jpg');
            }
        }

        .content.sect-explore-pokestops {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-pokestops-it.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-pokestops-mobile-it.jpg');
            }
        }

        .content.sect-explore-bag {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-bag-it.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-bag-mobile-it.jpg');
            }
        }

        .content.sect-explore-trainer {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-trainer-it.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-trainer-mobile-it.jpg');
            }
        }

        .content.sect-explore-medals {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-medals-it.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-medals-mobile-it.jpg');
            }
        }
    }

    body[data-page="pokemon"] {
        // LOCALIZED IMAGE OVERWRITES
        .sect-pokemon-catch {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-pokemon-catch-it.png');
            }
        }

        .sect-pokemon-pokedex {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-pokemon-pokedex-it.png');
            }
        }

        .sect-pokemon-traits {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-pokemon-traits-it.png');
            }
        }

        .sect-pokemon-evolution {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-pokemon-evolution-it.png');
            }
        }

        .sect-pokemon-eggs {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-pokemon-eggs-it.png');
            }
        }

        @include max-breakpoint($screen-xs-max) {
            .sect-pokemon-catch {
                background-image: url('#{$image-path}/sections/content-pokemon-catch-mobile-it.jpg');
            }

            .sect-pokemon-pokedex {
                background-image: url('#{$image-path}/sections/content-pokemon-pokedex-mobile-it.jpg');
            }

            .sect-pokemon-traits {
                background-image: url('#{$image-path}/sections/content-pokemon-traits-mobile-it.jpg');
            }

            .sect-pokemon-evolution {
                background-image: url('#{$image-path}/sections/content-pokemon-evolution-mobile-it.jpg');
            }
            .sect-pokemon-eggs {
                background-image: url('#{$image-path}/sections/content-pokemon-eggs-mobile-it.jpg');
            }
        }
    }

    body[data-page="teams-gyms"] {
        // LOCALIZED IMAGE OVERWRITES
        .sect-teams-main {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-main-it.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-main-mobile-it.jpg');
            }
        }

        .sect-teams-gyms {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-gyms-it.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-gyms-mobile-it.jpg');
            }
        }

        .sect-teams-prestige {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-prestige-it.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-prestige-mobile-it.jpg');
            }
        }

        .sect-teams-training {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-training-it.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-training-mobile-it.jpg');
            }
        }

        .sect-teams-attacking {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-attacking-it.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-attacking-mobile-it.jpg');
            }
        }

        .sect-teams-defending {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-defending-it.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-defending-mobile-it.jpg');
            }
        }
    }

    body[data-page="iap"] {
        // LOCALIZED IMAGE OVERWRITES
        .sect-iap-main {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-iap-main-it.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-iap-main-mobile-it.jpg');
            }
        }
    }

    .compatibility {
        &--header {
            .btn {
                line-height: 1.2;
                white-space: normal;
            }

            @include range-breakpoint($screen-sm, $screen-md) {
                h2 {
                    width: 365px;
                }
            }

            @include range-breakpoint($screen-md, $screen-lg) {
                h2 {
                    width: 480px;
                }
            }
        }
    }
}
