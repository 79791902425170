//
// Wells - text wells, often with icon on left
////////////////////////////////////////////////////////////////////////////////

.well {
    font-size: 0;
    padding: 10px 0;

    &--icon {
        @include inline-top();
        position: relative;
        top: 4px;
    }

    p.well--text {
        @include inline-top();
        font-size: 16px;
    }


    &-icon-left {
        .well--icon {
            margin-right: 24px;
        }

        p.well--text {
            width: calc(100% - 60px);
        }
    }
}
