//
// Explore Page
////////////////////////////////////////////////////////////////////////////////////////////////

body[data-page="explore"] {
    // navigation active state
    .navigation {
        &--explore {
            a {
                font-weight: 700;

                &:hover:after,
                &:after {
                    @include sprite(-60px -308px);
                }
            }

            &.is-disabled {
                a {
                    &:hover::after,
                    &:after {
                        @include sprite(-60px -308px);
                    }
                }
            }
        }

        // sprite scaled to 0.75
        @include range-breakpoint($screen-sm, 864px) {
            &--explore {
                a:after {
                    @include sprite(-45px -231px);
                    background-size: 310.5px auto;
                }

                &.is-disabled {
                    a {
                        &:hover::after,
                        &:after {
                            @include sprite(-45px -231px);
                            background-size: 310.5px auto;
                        }
                    }
                }
            }
        }
    }

    .content.content-flyout,
    .content.content-side-text {
        padding-top: 90px;
        padding-bottom: 90px;

        @include max-breakpoint ($screen-xs-max) {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }

    .content--description {
        padding-right: 0;
        padding-left: 0;
        font-size: 18px;
    }

    .sect-explore-main {
        h2 {
            font-size: 40px;
            font-weight: 400;
        }

        .content--description {
            font-weight: 400;
        }

        .content-hero--background-animate {
            background-image: url('#{$image-path}/sections/content-explore-main.jpg');
        }

        @include max-breakpoint($screen-xs-max) {
            .content-hero--background-animate {
                background-image: url('#{$image-path}/sections/content-explore-main-mobile.jpg');
            }

            .content--movement-side {
                padding-bottom: 94%;
            }
        }
    }

    .content--movement-side {
        position: absolute;
        bottom: -90px;
        top: auto;
        left: auto;
        z-index: 0;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .content--text-box-wide,
    .content--text-box-med,
    .content--text-box {
        position: relative;
        z-index: 1;
    }

    .content.sect-explore-maps {
        background-image: url('#{$image-path}/sections/content-explore-maps.jpg');

        .content--movement-side {
            width: 988px;
            height: 584px;
            left: 0;
            background-image: url('#{$image-path}/sections/side-explore-maps.png');
        }

        @include max-breakpoint($screen-xs-max) {
            background-image: url('#{$image-path}/sections/content-explore-maps-mobile.jpg');
            background-size: 100% auto;
            background-position: center bottom;
            padding-bottom: 98%;

            &.content-side-text-right .box-capped .box--bottom-cap::after {
                left: 28%;
            }
        }
    }

    .content.sect-explore-encounter {
        background-image: url('#{$image-path}/sections/content-explore-encounter.jpg');

        .content--movement-side {
            width: 308px;
            height: 520px;
            right: 0;
            background-image: url('#{$image-path}/sections/side-explore-encounter.png');
        }

        @include max-breakpoint($screen-xs-max) {
            background-image: url('#{$image-path}/sections/content-explore-encounter-mobile.jpg');
            background-size: 100% auto;
            background-position: center bottom;
            padding-bottom: 99%;
        }
    }

    .content.sect-explore-habitats {
        background-image: url('#{$image-path}/sections/content-explore-habitats.jpg');

        .content--movement-side {
            width: 297px;
            height: 515px;
            left: 0;
            background-image: url('#{$image-path}/sections/side-explore-habitats.png');
        }

        @include max-breakpoint($screen-xs-max) {
            background-image: url('#{$image-path}/sections/content-explore-habitats-mobile.jpg');
            background-size: 100% auto;
            background-position: center bottom;
            padding-bottom: 99%;
        }
    }

    .content.sect-explore-pokestops {
        background-image: url('#{$image-path}/sections/content-explore-pokestops.jpg');

        .content--movement-side {
            width: 850px;
            height: calc(100% + 120px);
            right: -253px;
            top: auto;
            bottom: -90px;
            background-image: url('#{$image-path}/sections/side-explore-pokestops.png');
            background-position: left top;
        }

        @include max-breakpoint($screen-xs-max) {
            background-image: url('#{$image-path}/sections/content-explore-pokestops-mobile.jpg');
            background-size: 100% auto;
            background-position: center bottom;
            padding-bottom: 65%;
        }
    }

    .content.sect-explore-bag {
        background-image: url('#{$image-path}/sections/content-explore-bag.jpg');

        .content--movement-side {
            width: 413px;
            height: 516px;
            left: 0;
            background-image: url('#{$image-path}/sections/side-explore-bag.png');
        }

        @include max-breakpoint($screen-xs-max) {
            background-image: url('#{$image-path}/sections/content-explore-bag-mobile.jpg');
            background-size: 100% auto;
            background-position: center bottom;
            padding-bottom: 104%;

            &.content-side-text-right .box-capped .box--bottom-cap::after {
                left: 32%;
            }
        }
    }

    .content.sect-explore-trainer {
        background-image: url('#{$image-path}/sections/content-explore-trainer.jpg');

        .content--movement-side {
            width: 419px;
            height: 531px;
            right: 0;
            background-image: url('#{$image-path}/sections/side-explore-trainer.png');
        }

        @include max-breakpoint($screen-xs-max) {
            background-image: url('#{$image-path}/sections/content-explore-trainer-mobile.jpg');
            background-size: 100% auto;
            background-position: center bottom;
            padding-bottom: 102%;

            &.content-side-text-left .box-capped .box--bottom-cap::after {
                left: 59%;
            }
        }
    }

    .content.sect-explore-medals {
        background-image: url('#{$image-path}/sections/content-explore-medals.jpg');

        .content--movement-side {
            width: 499px;
            height: 532px;
            left: 0;
            background-image: url('#{$image-path}/sections/side-explore-medals.png');
        }

        @include max-breakpoint($screen-xs-max) {
            background-image: url('#{$image-path}/sections/content-explore-medals-mobile.jpg');
            background-size: 100% auto;
            background-position: center bottom;
            padding-bottom: 103%;

            &.content-side-text-right .box-capped .box--bottom-cap::after {
                left: 45%;
            }
        }
    }

    @include max-breakpoint($screen-xs-max) {
        .content--movement-side {
            background: none !important; // Killing load of precise desktop selectors for background images
            display: none;
        }
    }
}
