//
// German
////////////////////////////////////////////////////////////////////////////////

html[lang="de"] {
    // appropriate app store language
    .btn-appstore-apple {
        background-position: 0 -80px;
    }
    .btn-appstore-google {
        background-position: -138px -80px;
    }

    // social box w/ long label cleanup
    .footer--social-box {
        span {
            display: block;
            margin-bottom: 4px;
        }

        li:first-child {
            margin-left: 0;
        }
    }

    .news-list-item--date,
    .news-summary--date {
        strong {
            &:after {
                content: '.';
                font-weight: normal;
            }
        }
    }

    // homepage hero vertical sizing on mobile
    body[data-page="homepage"] {

        @include range-breakpoint($screen-sm, 990px) {
            .content-hero h1 {
                font-size: 95px;
            }
            .btn.btn-play {
                transform: scale(0.9);
            }
            .phone-display h3 {
                font-size: 26px;
            }
            .content-hero--callout {
                /*font-size: 16px;*/
            }
        }

        @include range-breakpoint($screen-sm, 930px) {
            .content-hero h1 {
                font-size: 90px;
            }
            .btn.btn-play {
                transform: scale(0.8);
            }
            .phone-display h3 {
                font-size: 26px;
            }
        }

        @include range-breakpoint($screen-sm, 830px) {
            .content-hero h1 {
                font-size: 85px;
            }
            .btn.btn-play {
                transform: scale(0.7);
            }
            .phone-display h3 {
                font-size: 24px;
            }
            .content-hero--callout {
                font-size: 16px;
            }
        }

        @include max-breakpoint($screen-xs-max) {
            .btn.btn-play {
                transform: translateX(-54%) scale(1);
            }
            .content-hero {
                &--background {
                    height: 546px;
                }
                &::after {
                    top: 394px;
                }
                .content--movement {
                    height: calc(100% + 499px);
                }

                &.has-video {
                    .content-hero--background {
                        height: 562px;
                    }
                    &::after {
                        top: 410px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 606px;
                    }
                    &::after {
                        top: 454px;
                    }
                    .content--movement {
                        height: calc(100% + 499px);
                    }

                    &.has-video {
                        .content-hero--background {
                            height: 622px;
                        }
                        &::after {
                            top: 470px;
                        }
                        .content--movement {
                            height: calc(100% + 530px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint(600px) {
            .btn.btn-play {
                transform: translateX(-49%) scale(0.9);
            }
        }

        @include max-breakpoint(540px) {
            .btn.btn-play {
                transform: translateX(-45%) scale(0.8);
            }
        }

        @include max-breakpoint(480px) {
            .btn.btn-play {
                transform: translateX(-40%) scale(0.7);
            }
        }

        @include max-breakpoint(428px) {
            .btn.btn-play {
                transform: translateX(-35%) scale(0.6);
            }
            .content-hero {
                &--background {
                    height: 591px;
                }
                &::after {
                    top: 439px;
                }
                .content--movement {
                    height: calc(100% + 499px);
                }

                &.has-video {
                    .content-hero--background {
                        height: 607px;
                    }
                    &::after {
                        top: 455px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 606px;
                    }
                    &::after {
                        top: 454px;
                    }
                    .content--movement {
                        height: calc(100% + 499px);
                    }

                    &.has-video {
                        .content-hero--background {
                            height: 622px;
                        }
                        &::after {
                            top: 470px;
                        }
                        .content--movement {
                            height: calc(100% + 530px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint(370px) {
            .btn.btn-play {
                transform: translateX(-31%) scale(0.5);
            }
        }

        @include max-breakpoint(360px) {
            .content-hero {
                h1 {
                    font-size: 55px;
                }
                &--background {
                    height: 558px;
                }
                &::after {
                    top: 406px;
                }
                .content--movement {
                    height: calc(100% + 499px);
                }

                &.has-video {
                    .content-hero--background {
                        height: 574px;
                    }
                    &::after {
                        top: 422px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 573px;
                    }
                    &::after {
                        top: 421px;
                    }
                    .content--movement {
                        height: calc(100% + 499px);
                    }
                    &.has-video {
                        .content-hero--background {
                            height: 589px;
                        }
                        &::after {
                            top: 437px;
                        }
                        .content--movement {
                            height: calc(100% + 530px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint(350px) {
            .content-hero {
                &.has-video {
                    .content-hero--background {
                        height: 589px;
                    }
                    &::after {
                        top: 437px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &.has-video {
                        .content-hero--background {
                            height: 604px;
                        }
                        &::after {
                            top: 452px;
                        }
                        .content--movement {
                            height: calc(100% + 530px);
                        }
                    }
                }
            }
        }


        .content--tagline {
            font-size: 48px !important;

            @include max-breakpoint(900px) {
                font-size: 42px !important;
            }

            @include max-breakpoint($screen-xs-max) {
                font-size: 24px !important;
            }
        }

        // LOCALIZED IMAGE OVERWRITES
        .section-home-explore {
            .content--movement-side {
                width: 520px;
                height: 624px;
                background-image: url('#{$image-path}/sections/side-home-explore-de.png');
                left: 46%;
                top: auto;
                bottom: -60px;
                z-index: 2;
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-home-explore-mobile-de.jpg');
            }
        }
    }

    body[data-page="go-plus"] {
        @include range-breakpoint($screen-sm, $screen-lg) {
            .content-side-text h2 {
                font-size: 30px;
            }
        }

        // LOCALIZED IMAGE OVERWRITES
        .sect-goplus-alerts {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-goplus-alerts-de.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-goplus-alerts-mobile-de.jpg');
            }
        }
    }

    body[data-page="explore"] {
        @include max-breakpoint($screen-xs-max) {
            .sect-explore-main h2 {
                font-size: 24px;
            }
        }

        // LOCALIZED IMAGE OVERWRITES
        .content.sect-explore-maps {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-maps-de.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-maps-mobile-de.jpg');
            }
        }

        .content.sect-explore-encounter {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-encounter-de.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-encounter-mobile-de.jpg');
            }
        }

        .content.sect-explore-pokestops {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-pokestops-de.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-pokestops-mobile-de.jpg');
            }
        }

        .content.sect-explore-bag {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-bag-de.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-bag-mobile-de.jpg');
            }
        }

        .content.sect-explore-trainer {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-trainer-de.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-trainer-mobile-de.jpg');
            }
        }

        .content.sect-explore-medals {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-medals-de.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-medals-mobile-de.jpg');
            }
        }
    }

    body[data-page="pokemon"] {
        @include max-breakpoint($screen-xs-max) {
            .box h2 {
                font-size: 26px;
            }
        }

        // LOCALIZED IMAGE OVERWRITES
        .sect-pokemon-catch {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-pokemon-catch-de.png');
            }
        }

        .sect-pokemon-pokedex {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-pokemon-pokedex-de.png');
            }
        }

        .sect-pokemon-traits {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-pokemon-traits-de.png');
            }
        }

        .sect-pokemon-evolution {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-pokemon-evolution-de.png');
            }
        }

        .sect-pokemon-eggs {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-pokemon-eggs-de.png');
            }
        }

        @include max-breakpoint($screen-xs-max) {
            .sect-pokemon-catch {
                background-image: url('#{$image-path}/sections/content-pokemon-catch-mobile-de.jpg');
            }

            .sect-pokemon-pokedex {
                background-image: url('#{$image-path}/sections/content-pokemon-pokedex-mobile-de.jpg');
            }

            .sect-pokemon-traits {
                background-image: url('#{$image-path}/sections/content-pokemon-traits-mobile-de.jpg');
            }

            .sect-pokemon-evolution {
                background-image: url('#{$image-path}/sections/content-pokemon-evolution-mobile-de.jpg');
            }
            .sect-pokemon-eggs {
                background-image: url('#{$image-path}/sections/content-pokemon-eggs-mobile-de.jpg');
            }
        }
    }

    body[data-page="teams-gyms"] {
        // LOCALIZED IMAGE OVERWRITES
        .sect-teams-main {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-main-de.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-main-mobile-de.jpg');
            }
        }

        .sect-teams-gyms {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-gyms-de.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-gyms-mobile-de.jpg');
            }
        }

        .sect-teams-prestige {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-prestige-de.png');
            }
            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-prestige-mobile-de.jpg');
            }
        }

        .sect-teams-training {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-training-de.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-training-mobile-de.jpg');
            }
        }

        .sect-teams-attacking {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-attacking-de.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-attacking-mobile-de.jpg');
            }
        }

        .sect-teams-defending {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-defending-de.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-defending-mobile-de.jpg');
            }
        }
    }

    body[data-page="iap"] {
        // LOCALIZED IMAGE OVERWRITES
        .sect-iap-main {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-iap-main-de.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-iap-main-mobile-de.jpg');
            }
        }
    }

    .compatibility {
        &--header {
            .btn {
                line-height: 1.2;
                white-space: normal;
            }

            @include range-breakpoint($screen-sm, $screen-md) {
                h2 {
                    width: 365px;
                }
            }
        }
    }
}
