//
// Social Links
////////////////////////////////////////////////////////////////////////////////

.social {
    @include inline-middle();
    width: 32px;
    height: 32px;
    background: transparent;
    background-image: url($image-social-sprite);
    background-repeat: no-repeat;
    background-size: 224px auto;
    color: transparent;
    font-size: 0;

    &-facebook {
        background-position: 0 0;
    }
    &-twitter {
        background-position: -32px 0;
    }
    &-youtube {
        background-position: -64px 0;
    }
    &-instagram {
        background-position: -96px 0;
    }
    &-tumblr {
        background-position: -128px 0;
    }
    &-google-plus {
        background-position: -160px 0;
    }
    &-reddit {
        background-position: -192px 0;
    }
}
