//
// Page styles
////////////////////////////////////////////////////////////////////////////////

html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    font-family: $font-family-normal;
    font-size: 16px;
    line-height: 1.4;
    color: #fff;

    // scroll lock
    &.is-scroll-lock {
		overflow: hidden;
		position: fixed;
	}

    &.has-smartbanner {
        padding-top: 78px;
    }

}

* {
    box-sizing: border-box;
}

::selection {
  color: $color-pokemon-yellow;
  background: $color-pokemon-blue;
}

// slight fixes for GUS
.pokemon-gus-container {
    overflow: hidden;
    z-index: 9999;
    position: relative;
    height: 62px;

    @include max-breakpoint($screen-xs-max) {
        height: 57px;
    }
}

.is-hidden {
    display: none !important;
}

[data-scroll-animation] {
  opacity: 0;
}


body[data-app-release-state="prerelease"] {
    [data-postrelease] {
        display: none !important;
    }
}

body[data-app-release-state="postrelease"] {
    [data-prerelease] {
        display: none !important;
    }
}
