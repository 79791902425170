//
// Home Page
////////////////////////////////////////////////////////////////////////////////////////////////

body[data-page="go-plus"] {

    // navigation active state
    .navigation {
        &--plus {
            a {
                font-weight: 700;

                &:after {
                    @include sprite(0 -308px);
                }
            }

            &.is-disabled {
                a {
                    &:hover::after,
                    &:after {
                        @include sprite(0 -308px);
                    }
                }
            }
        }

        // sprite scaled to 0.75
        @include range-breakpoint($screen-sm, 864px) {
            &--plus {
                a:after {
                    @include sprite(0 -231px);
                    background-size: 310.5px auto;
                }

                &.is-disabled {
                    a {
                        &:hover::after,
                        &:after {
                            @include sprite(0 -231px);
                            background-size: 310.5px auto;
                        }
                    }
                }
            }
        }
    }

    // order link with no href
    // This link opens a modal if href has a comma in it (multiple hrefs in coma separated list)
    .btn-order[href=""] {
        display: none;
    }


    .sect-goplus-main {
        padding-top: 66px;
        padding-bottom: 66px;

        .content--logo-small {
            width: 131px;
            height: 86px;
            margin-right: 20px;
            margin-bottom: 20px;
        }

        .btn {
            margin-bottom: 32px;
        }

        h2 {
            width: 80%;

            @include min-breakpoint($screen-sm) {
              font-size: 26px;
            }

            @include min-breakpoint($screen-md) {
              font-size: 30px;
            }
        }

        p {
            clear: left;
        }

        .content--movement-side {
            display: none;
        }

        .content-hero--background-animate {
          background-image: url('#{$image-path}/sections/content-goplus-main.jpg');
        }

        @include max-breakpoint($screen-sm-max) {
            h2 {
                width: 100%;
                text-align: center;
                padding-top: 20px;
            }
        }

        @include min-breakpoint($screen-sm) {
            h2 {
                text-align: left;
            }
        }

        @include max-breakpoint($screen-xs-max) {
            padding-top: 40px;
            padding-bottom: 40px;

            .content-hero--background-animate {
              background-image: url('#{$image-path}/sections/content-goplus-main-mobile.jpg');
              background-size: 100% auto;
              background-position: center top;
            }

            h2 {
                width: 100%;
                padding-bottom: 118%;
                font-size: 35px;
            }

            .content--description {
                .content--logo-small {
                    width: 131px;
                    height: 86px;
                    margin: 0 auto 20px auto;
                    padding: 0;
                }
            }

            .btn {
                transform-origin: 0 0;
                transform: scale(0.72);
                margin-top: 32px;
            }
        }

        @include max-breakpoint (359px) {
            .btn {
                transform: scale(0.62);
            }
        }
    }

    .sect-goplus-alerts {
        padding-top: 80px;
        padding-bottom: 120px;
        background-image: url('#{$image-path}/sections/content-goplus-alerts.jpg');

        .content--movement-side {
            width: 1017px;
            height: 503px;
            background: transparent url('#{$image-path}/sections/side-goplus-alerts.png') no-repeat center bottom;
            top: auto;
            bottom: -120px;
            z-index: 0;
        }

        .content--text-box {
            position: relative;
            z-index: 1;
        }

        @include max-breakpoint($screen-xs-max) {
            background-image: url('#{$image-path}/sections/content-goplus-alerts-mobile.jpg');
            background-size: 100% auto;
            background-position: center bottom;
            padding-top: 40px;
            padding-bottom: 91%;
        }
    }

    .sect-goplus-nearby {
        padding-top: 80px;
        padding-bottom: 100px;
        background-image: url('#{$image-path}/sections/content-goplus-nearby.jpg');

        .content--movement-side {
            width: 583px;
            height: 435px;
            background: transparent url('#{$image-path}/sections/side-goplus-nearby.png') no-repeat center bottom;
            top: auto;
            right: -10px;
            left: auto;
            bottom: -100px;
            z-index: 0;
        }

        @include max-breakpoint($screen-xs-max) {
            background-image: url('#{$image-path}/sections/content-goplus-nearby-mobile.jpg');
            background-size: 100% auto;
            background-position: center bottom;
            padding-top: 40px;
            padding-bottom: 80%;
        }
    }


    @include max-breakpoint($screen-xs-max) {
        .content--movement-side {
            background: none !important; // Killing load of precise desktop selectors for background images
            display: none;
        }
    }
}
