//
// News article
////////////////////////////////////////////////////////////////////////////////

.news-article {

    &--image {
        text-align: center;

        img {
            display: block;
            width: 100%;
            height: auto;
            margin: 0 auto 32px auto;
        }
    }

    &--image-horizontal {
        max-width: 286px;
    }

    &--image-vertical {
        max-width: 173px;
    }

    &--headline {

    }

    &--body {
        font-size: 17px;
        color: $color-dark-grey;
        line-height: 1.4;
        font-weight: 400;
    }
}
