//
// content boxes
////////////////////////////////////////////////////////////////////////////////

.content {
    @include clearfix();
    //TODO: border-bottom: 2px #fff solid;
    border-top: 2px #fff solid;
    padding: 60px 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;

    h2 {
        margin-bottom: 7px;
        line-height: 1;
    }

    h3 {
        font-size: 32px;
        font-weight: 900;
        text-shadow: 0 0 5px rgba(0,0,0,0.4);
        margin: 16px 0 0 0;
        text-align: center;
    }

    .disclaimer {
        position: absolute;
        bottom: 20px;
        right: 20px;
        z-index: 3;
    }

    &--news-summary-container {
        min-height: 69px;
    }

    // inner wrapper
    &--wrapper {
        @include bind-width();
        position: relative;
        z-index: 2;
    }

    &--header {
        @include clearfix();

        h2 {
            float: left;
        }
        p {
            float: right;

            @include max-breakpoint($screen-xs-max) {
                float: none;
                clear: left;
            }
        }
    }

    // flyout container
    &--flyout {
        display: inline-block;
    }

    &--header {
        text-align: left;
    }

    &--aside {
        display: inline-block;
        width: 50%;

        @include max-breakpoint($screen-xs-max) {
            width: 100%;
        }
    }

    &--tagline {
        font-size: 75px !important;
        font-weight: 300 !important;
        line-height: 1;
        text-transform: uppercase;
        margin-bottom: 48px;
        position: relative;

        @include max-breakpoint(900px) {
            font-size: 62px !important;
        }

        @include max-breakpoint($screen-xs-max) {
            font-size: 46px !important;
        }
    }

    &--description {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 30px;
        padding-right: 80px;

        &:last-child {
            margin-bottom: 0;
        }

        em {
            white-space: nowrap;
        }
    }

    &-full-width {
        .content--description {
            padding-right: 0;
        }
    }

    &--more {
        text-align: left;
    }

    &--movement-side {
        position: absolute;
        left: 0;
        top: -60px;
        background-repeat: no-repeat;
        width: 50vw;
        height: calc(100% + 120px);
        background-repeat: no-repeat;
        z-index: 0;
    }

    &--text-box {
        display: inline-block;
        width: 50%;
        position: relative;
        z-index: 1;

        + .content--aside {
            width: 50%;

            @include max-breakpoint($screen-xs-max) {
                width: 100%;
            }
        }

        @include max-breakpoint($screen-xs-max) {
            width: 100%;
        }
    }

    &--text-box-med {
        display: inline-block;
        width: 60%;
        position: relative;
        z-index: 1;

        + .content--aside {
            width: 40%;

            @include max-breakpoint($screen-xs-max) {
                width: 100%;
            }
        }

        @include max-breakpoint($screen-xs-max) {
            width: 100%;
        }
    }

    &--text-box-wide {
        display: inline-block;
        width: 66.666%;
        position: relative;
        z-index: 1;

        + .content--aside {
            width: 33.333%;

            @include max-breakpoint($screen-xs-max) {
                width: 100%;
            }
        }

        @include max-breakpoint($screen-xs-max) {
            width: 100%;
        }
    }

    // nested
    .content {
        border-top: 1px #fff solid;
        border-bottom: 0;
        padding: 21px 0 30px 0;
        margin-top: 25px;
        z-index: 1;

        @include max-breakpoint($screen-xs-max) {
            border: 0;
        }
    }

    &--background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        filter: blur(15px);
        opacity: 0.2;
        z-index: 0;
    }

    &-news {
        position: relative;
        min-height: 238px;
        padding-top: 48px;
        padding-bottom: 48px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-image: url('#{$image-path}/sections/news-background-even.jpg');

        > canvas {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &:nth-child(2n+1) {
            background-image: url('#{$image-path}/sections/news-background-odd.jpg');
        }
    }

    &-news-details {
        background-image: url('#{$image-path}/sections/news-details-background.jpg');

        @include min-breakpoint($screen-sm) {
            min-height: calc(100vh - 674px);
        }

        @include min-breakpoint($screen-md) {
            min-height: calc(100vh - 582px);
        }
    }

    // hero variant
    &-hero {
        position: relative;
        padding-top: 200px;
        padding-bottom: 0;
        overflow: hidden;
        border-top: 0;

        h1 {
            font-size: 100px;
            font-weight: 900;
            line-height: 1;
            margin: 75px 0 4px 0;
            position: relative;

            /*span  {
                color: red !important;
            }*/
        }

        p.dark-box {
            margin: 0 0 12px 0;
            font-size: 20px;
            font-weight: 700;
        }

        .content--movement,
        &:before,
        &:after {
            content: '';
            display: block;
            width: 100%;
            z-index: 0;
            position: absolute;
            left: 0;
            opacity: 0.73;
        }

        &:before {
            height: 302px;
            top: -28px;
            background: linear-gradient(to bottom,  rgba(4,218,190,1) 0%,rgba(155,255,123,0.43) 55%,rgba(155,255,123,0) 100%);
            z-index: 1;
        }

        &:after {
            height: 150px;
            bottom: -2px;
            background: linear-gradient(to bottom,  rgba(155,255,123,0) 0%,rgba(155,255,123,0.43) 45%,rgba(4,218,190,1) 100%);
        }

        .content--movement {
            height: 217px;
            bottom: -2px;
            z-index: 1;
        }


        .content--movement-inner {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: transparent url('#{$image-path}/hexagon-fade-up.png') repeat-x 0 0;
            z-index: 2;
        }

        &--background {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            z-index: 0;
            transform: translateZ(0) scale(1);
            transform-origin: 50% 50%;

            &-animate {
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
            }
        }

        @include range-breakpoint(865px, $screen-md) {
            padding-top: 164px;
        }

        @include range-breakpoint($screen-sm, 864px) {
            padding-top: 140px;
        }

        @include max-breakpoint ($screen-xs-max) {
            padding-top: 175px;
            text-align: center;
            background-size: auto 545px;
            background-position: center top;

            &--home {
              padding-top: 85px;
            }

            h1 {
                font-size: 66px;
                margin-top: 135px;
            }

            &:before {
                height: 144px;
                top: -2px;
                background: linear-gradient(to bottom,  rgba(4,218,190,1) 0%,rgba(155,255,123,0.27) 55%,rgba(155,255,123,0) 100%);
            }

            &:after {
                bottom: auto;
                top: 395px;
                background: linear-gradient(to bottom,  rgba(155,255,123,0) 0%,rgba(155,255,123,0.27) 45%,rgba(4,218,190,1) 100%);
            }

        }

        @include max-breakpoint (333px) {
            h1 {
                font-size: 62px;
            }
        }
    }

    &-hero-small {
        position: relative;
        padding-top: 180px;
        padding-bottom: 0;
        overflow: hidden;
        border-top: 0;
        /*border-bottom: 2px #fff solid;*/
        background: linear-gradient(to top,  rgb(12,85,167) 0%,rgb(49,231,248) 100%);

        .content--movement {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .content--movement-inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: transparent url('#{$image-path}/hexagon-fade-up.png') repeat-x 0 0;
        }

        @include range-breakpoint(865px, $screen-md) {
            padding-top: 149px;
        }

        @include range-breakpoint($screen-sm, 864px) {
            padding-top: 124px;
        }

        @include max-breakpoint ($screen-xs-max) {
            padding-top: 122px;
        }
    }

    &-flyout {
        .content--tagline {
            &:after {
                content: '';
                display: block;
                width: 60vw;
                height: 0;
                border-bottom: 1px #fff solid;
                position: absolute;
                top: calc(100% + 21px);
            }
        }

        // flyout container
        .content--flyout {
            width: 60%;
            text-align: left;
        }

        @include max-breakpoint ($screen-xs-max) {

            .content--flyout {
                width: 100%;
            }

            .content--tagline {
                &:after {
                    width: 100%;
                }
            }
        }
    }

    &-flyout-wide {
        .content--tagline {
            &:after {
                content: '';
                display: block;
                width: 70vw;
                height: 0;
                border-bottom: 1px #fff solid;
                position: absolute;
                top: calc(100% + 21px);
            }
        }

        // flyout container
        .content--flyout {
            width: 70%;
            text-align: left;
        }

        @include max-breakpoint ($screen-xs-max) {

            .content--flyout {
                width: 100%;
            }
            .content--tagline {
                &:after {
                    width: 100%;
                }
            }
        }
    }

    // flyout left variant
    &-flyout-left {
        text-align: left;

        .content--tagline {
            &:after {
                right: 80px;
            }
        }
    }

    // flyout right variant
    &-flyout-right {
        text-align: right;


        .content--tagline {
            &:after {
                left: 0;
            }
        }
    }

    &-side-text {
        h2 {
            font-weight: 400;
            font-size: 40px;
            margin-bottom: 10px;
        }

        p {
            font-weight: 400;
            font-size: 20px;
        }

        .content--description {
            margin-bottom: 28px;
        }
    }

    &-side-text-left {
        text-align: left;

    }

    &-side-text-right {
        text-align: right;
    }

    &-coming-soon {
        min-height: 400px;
        padding-top: 200px !important;
        padding-bottom: 200px !important;

        h2 {
            width: 100% !important;
            font-size: 40px !important;
            font-weight: 700;
            margin: 0;
            padding: 0 15px;
            text-shadow: 0 0 10px rgba(0,0,0,0.5);
        }

        @include max-breakpoint($screen-xs-max) {
            h2 {
                font-size: 25px !important;
            }
        }
    }

    @include max-breakpoint($screen-xs-max) {
        padding-left: 0;
        padding-right: 0;

        .content {
            .content {
                padding-top: 0;
                padding-left: 0;
                padding-right: 0;
                margin-top: 32px;
                border: 0;
            }


            .content--header {
                position: relative;
                padding-top: 24px;

                &:before {
                    content: '';
                    width: calc(100% + 30px);
                    height: 0;
                    border-top: 2px #fff solid;
                    position: absolute;
                    top: -2px;
                    left: -15px;
                    z-index: 2;
                }
            }
        }


        .content--tagline {
            margin-top: 16px;

            &:after {
                left: auto;
                right: auto;
            }
        }

        .content--description {
            padding: 0;
        }

        .content--movement {
            height: calc(100% + 449px);
            background: rgb(49,204,255);
            background: linear-gradient(to bottom,  rgb(49,204,255) 0%,rgb(51,141,243) 90%,rgb(51,171,249) 100%);
        }

        .content--movement-inner {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: transparent url('#{$image-path}/hexagon-fade-center.png') repeat-x center center;
            background-size: 221px auto;
        }

        &.content-hero-small {
            .content--movement {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .content--movement-inner {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: transparent url('#{$image-path}/hexagon-fade-up.png') repeat-x 0 0;
            }
        }
    }

    @include max-breakpoint (333px) {
        h2 {
            font-size: 20px;
        }
    }
}
