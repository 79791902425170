//
// Back to top button
////////////////////////////////////////////////////////////////////////////////

.back-to-top {
    width: 162px;
    height: 45px;
    background: transparent url('#{$image-path}/back-to-top-curve.png') no-repeat left top;
    background-size: 162px 45px;
    position: fixed;
    top: 0;
    right: 0;
    transition: top 0.3s ease-out;
    z-index: 999999;

    button {
        @include sprite(-259px 0);
        padding: 0;
        border: 0;
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 0;
        color: transparent;
        width: 44px;
        height: 44px;
        border-radius: 22px;
        box-sizing: border-box;
        outline: 0 !important;
    }

    &.is-up {
        top: -64px;
    }
}
