//
// Teams & Gyms Page
////////////////////////////////////////////////////////////////////////////////////////////////

body[data-page="teams-gyms"] {
    // navigation active state
    .navigation {
        &--teams {
            a {
                font-weight: 700;

                &:after {
                    @include sprite(-180px -308px);
                }
            }

            &.is-disabled {
                a {
                    &:hover::after,
                    &:after {
                        @include sprite(-180px -308px);
                    }
                }
            }
        }

        // sprite scaled to 0.75
        @include range-breakpoint($screen-sm, 864px) {
            &--teams {
                a:after {
                    @include sprite(-135px -231px);
                    background-size: 310.5px auto;
                }

                &.is-disabled {
                    a {
                        &:hover::after,
                        &:after {
                            @include sprite(-135px -231px);
                            background-size: 310.5px auto;
                        }
                    }
                }
            }
        }
    }

    .content--movement-side {
        position: absolute;
        bottom: -60px;
        top: auto;
        left: auto;
        z-index: 0;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .content--description {
        padding-right: 0;
    }

    .sect-teams-main {
        padding: 80px 0 120px 0;

        .content-hero--background-animate {
            background-image: url('#{$image-path}/sections/content-teams-main.jpg');
        }

        .content--movement-side {
            width: 800px;
            height: calc(100% + 180px);
            right: -40%;
            bottom: -120px;
            background-image: url('#{$image-path}/sections/side-teams-main.png');
            background-position: left top;
            background-size: auto;
            z-index: 1;

            @include max-breakpoint($screen-md-max) {
                right: -380px;
                transform: scaleX(.8) scaleY(.8);
                transform-origin: 100% 100%;
            }
        }

        @include max-breakpoint($screen-xs-max) {
            padding: 40px 0 152% 0;
            background-image: url('#{$image-path}/sections/content-teams-main-mobile.jpg');
            background-size: 100% auto;
            background-position: center bottom;

            .content-hero--background-animate {
                background: none !important; // Killing load of precise desktop selectors for background images
                display: none;
            }
        }
    }
    .sect-teams-gyms {
        background-image: url('#{$image-path}/sections/content-teams-gyms.jpg');

        .content--movement-side {
            width: 850px;
            height: calc(100% + 80px);
            left: -25%;
            bottom: auto;
            top: -20px;
            background-image: url('#{$image-path}/sections/side-teams-gyms.png');
            background-position: right top;
            background-size: auto;
        }

        @include max-breakpoint($screen-xs-max) {
            padding: 40px 0 83% 0;
            background-image: url('#{$image-path}/sections/content-teams-gyms-mobile.jpg');
            background-size: 100% auto;
            background-position: center bottom;
        }
    }
    .sect-teams-prestige {
        padding: 110px 0 125px 0;
        background-image: url('#{$image-path}/sections/content-teams-prestige.jpg');

        .content--movement-side {
            width: 308px;
            height: 520px;
            right: 0;
            bottom: -125px;
            background-image: url('#{$image-path}/sections/side-teams-prestige.png');
        }

        @include max-breakpoint($screen-xs-max) {
            padding: 40px 0 134% 0;
            background-image: url('#{$image-path}/sections/content-teams-prestige-mobile.jpg');
            background-size: 100% auto;
            background-position: center bottom;
        }
    }

    .sect-teams-training {
        padding: 110px 0 125px 0;
        background-image: url('#{$image-path}/sections/content-teams-training.jpg');

        .content--movement-side {
            width: 388px;
            height: 527px;
            left: 0;
            bottom: -125px;
            background-image: url('#{$image-path}/sections/side-teams-training.png');

            @include max-breakpoint($screen-md-max) {
                left: -70px;
            }
        }

        @include max-breakpoint($screen-xs-max) {
            padding: 40px 0 141% 0;
            background-image: url('#{$image-path}/sections/content-teams-training-mobile.jpg');
            background-size: 100% auto;
            background-position: center bottom;
        }
    }
    .sect-teams-attacking {
        padding: 70px 0;
        background-image: url('#{$image-path}/sections/content-teams-attacking.jpg');

        .content--movement-side {
            width: 270px;
            height: 470px;
            right: 0;
            background-image: url('#{$image-path}/sections/side-teams-attacking.png');
        }

        @include max-breakpoint($screen-xs-max) {
            padding: 40px 0 154% 0;
            background-image: url('#{$image-path}/sections/content-teams-attacking-mobile.jpg');
            background-size: 100% auto;
            background-position: center bottom;
        }
    }
    .sect-teams-defending {
        padding: 80px 0;
        background-image: url('#{$image-path}/sections/content-teams-defending.jpg');

        .content--movement-side {
            width: 270px;
            height: 470px;
            left: 0;
            background-image: url('#{$image-path}/sections/side-teams-defending.png');
        }

        @include max-breakpoint($screen-xs-max) {
            padding: 40px 0 154% 0;
            background-image: url('#{$image-path}/sections/content-teams-defending-mobile.jpg');
            background-size: 100% auto;
            background-position: center bottom;
        }
    }

    @include max-breakpoint($screen-xs-max) {
        .content--movement-side {
            background: none !important; // Killing load of precise desktop selectors for background images
            display: none;
        }
    }
}
