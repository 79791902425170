//
// Home Page
////////////////////////////////////////////////////////////////////////////////////////////////

body[data-page="homepage"] {

    .icon-caret-right-circle {
        top: -1px;
    }

    .content {
        .disclaimer {
            bottom: 165px;

            @include max-breakpoint($screen-xs-max) {
                bottom: auto;
                top: 20px;
                position: static;
                margin: 12px 0;
                text-align: center;
            }
        }

        .content {
            margin-top: 75px;

            .content--wrapper {
                p:last-child {
                    margin-top: 44px;
                    margin-bottom: 0;
                }

                .content--header {
                    p {
                        margin: 0;

                        @include max-breakpoint($screen-xs-max) {
                            display: none;
                        }
                    }

                    h2 {
                        @include max-breakpoint($screen-xs-max) {
                            margin-bottom: 12px;
                        }
                    }
                }
            }

            @include max-breakpoint($screen-xs-max) {
                margin-top: 25px;
            }
        }
    }



    .content-hero {
        background-color: $color-pokemon-blue;
    }

    @include max-breakpoint($screen-xs-max) {
        .content-hero {
            .btn.btn-play {
                position: relative;
                left: 50%;
                transform: translateX(-47%);
            }
            &--background {
                border-bottom: solid 2px #fff;
                height: 542px;
            }

            &::after {
                top: 390px;
            }

            .phone-display .box {
                margin-top: 64px;
                margin-bottom: 32px;
            }

            .content--movement {
                height: calc(100% + 440px);
            }

            &.has-video {
                .content-hero--background {
                    height: 493px;
                }

                &::after {
                    top: 341px;
                }

                .phone-display .box {
                    margin-top: 0;
                    margin-bottom: 32px;
                }

                .content--movement {
                    height: calc(100% + 560px);
                }
            }
        }

        &[data-app-release-state="postrelease"] {
            .content-hero {
                &--background {
                    border-bottom: solid 2px #fff;
                    height: 627px;
                }

                &::after {
                    top: 475px;
                }

                .phone-display .box {
                    margin-bottom: 32px;
                    margin-top: 64px;
                }

                .content--movement {
                    height: calc(100% + 429px);
                }

                &.has-video {
                    .content-hero--background {
                        border-bottom: solid 2px #fff;
                        height: 556px;
                    }

                    &::after {
                        top: 404px;
                    }

                    .phone-display .box {
                        margin-bottom: 32px;
                        margin-top: 0;
                    }

                    .content--movement {
                        height: calc(100% + 564px);
                    }
                }
            }
        }
    }

    @include max-breakpoint(333px) {
        .content-hero {
            .content--movement {
                height: calc(100% + 432px);
            }
        }
    }

    .section-home-goplus {
        background-image: url('#{$image-path}/sections/content-home-goplus.jpg');

        .content--movement-side {
            width: 597px;
            height: 409px;
            background: transparent url('#{$image-path}/sections/side-home-goplus.png') no-repeat center center;
            top: 35px;
            left: 4.8%;
        }
    }

    .section-home-explore {
        background-image: url('#{$image-path}/sections/content-home-explore.jpg');

        .content--movement-side {
            width: 520px;
            height: 624px;
            background: transparent url('#{$image-path}/sections/side-home-explore.png') no-repeat center center;
            left: 46%;
            top: auto;
            bottom: -60px;
            z-index: 2;
        }
    }

    // when news errors or has less than 3 items
    .sect-home-news {
        &.is-error {
            border-top: 0;

            .content--header {
                display: none;
            }

            @include max-breakpoint($screen-xs-max) {
                .content--news-summary-container {
                    display: none;
                }
            }
        }

        &.is-minimal {
            .content--news-link {
                display: none !important;
            }
        }
    }

    @include max-breakpoint($content-max-width) {
        .section-home-goplus {
            .content--movement-side {
                left: 0;
            }
        }
    }

    @include max-breakpoint(1000px) {
        .section-home-explore {
            .content--movement-side {
                transform-origin: 0 100%;
                transform: scale(0.9);
            }
        }
    }

    @include max-breakpoint(900px) {
        .section-home-goplus {
            .content--movement-side {
                transform-origin: 0 100%;
                transform: scale(0.8);
            }
        }
    }

    @include max-breakpoint(800px) {
        .section-home-goplus {
            .content--movement-side {
                transform-origin: 0 100%;
                transform: scale(0.8);
                left: -10px;
            }
        }
        .section-home-explore {
            .content--movement-side {
                left: 40%;
            }
        }
    }

    @include max-breakpoint($screen-xs-max) {
        .content.content-hero {
            background-position: 62% top;
        }

        .btn {
            &.btn-play {
                margin-top: 14px;
                margin-bottom: 42px;
            }

            &.btn-appstore {
                margin-top: 15px;
            }
        }

        .content.content-hero {
            p.dark-box {
                background: transparent;
                padding: 0;
                border-radius: 0;
                margin-bottom: 32px;
            }
        }

        .content.section-home-goplus {
            background: #2b5d83 url('#{$image-path}/sections/content-home-goplus-mobile.jpg') no-repeat center top;
            background-size: 100% auto;
            padding-top: 94%;
        }

        .content.section-home-explore {
            background: #bfcbbf url('#{$image-path}/sections/content-home-explore-mobile.jpg') no-repeat center bottom;
            background-size: 100% auto;
            padding-bottom: 163%;
        }
    }

    @include range-breakpoint ($screen-sm, 830px) {
        .btn-watch-trailer {
            transform-origin: 0 0;
            transform: scale(0.95);
        }
    }

    @include max-breakpoint(350px) {
        .btn.btn-play {
            margin-top: 29px;
            transform-origin: 0 50%;
            transform: scale(0.85);
        }
    }

    &.is-ios {
        .btn-appstore-toggle-by-platform.btn-appstore-google {
            display: none !important;
        }
    }

    &.is-android {
        .btn-appstore-toggle-by-platform.btn-appstore-apple {
            display: none !important;
        }
    }

    @include max-breakpoint($screen-xs-max) {
        .content--movement-side {
            background: none !important; // Killing load of precise desktop selectors for background images
            display: none;
        }
    }
}
