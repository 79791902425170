//
// In App Purchases Page
////////////////////////////////////////////////////////////////////////////////////////////////

body[data-page="iap"] {
    // navigation active state
    .navigation {
        &--purchases {
            a {
                font-weight: 700;

                &:after {
                    @include sprite(-300px -308px);
                }
            }

            &.is-disabled {
                a {
                    &:hover::after,
                    &:after {
                        @include sprite(-300px -308px);
                    }
                }
            }
        }

        // sprite scaled to 0.75
        @include range-breakpoint($screen-sm, 864px) {
            &--purchases {
                a:after {
                    @include sprite(-225px -231px);
                    background-size: 310.5px auto;
                }

                &.is-disabled {
                    a {
                        &:hover::after,
                        &:after {
                            @include sprite(-225px -231px);
                            background-size: 310.5px auto;
                        }
                    }
                }
            }
        }
    }

    .iap-list .box h2 {
        font-weight: 400;
    }

    .sect-iap-main {
        padding-top: 70px;
        padding-bottom: 70px;

        .content-hero--background-animate {
            background-image: url('#{$image-path}/sections/content-iap-main.jpg');
        }

        .content--movement-side {
            background-image: url('#{$image-path}/sections/side-iap-main.png');
            background-position: left bottom;
            background-repeat: no-repeat;
            background-size: auto;
            bottom: -80px;
            height: calc(100% + 180px);
            left: auto;
            position: absolute;
            right: 0;
            top: auto;
            width: 325px;
            z-index: 1;
        }

        @include max-breakpoint($screen-xs-max) {
            padding: 40px 0 115%;
            background-image: url('#{$image-path}/sections/content-iap-main-mobile.jpg');
            background-size: 100% auto;
            background-position: center bottom;

            .content-hero--background-animate {
                background: none !important; // Killing load of precise desktop selectors for background images
                display: none;
            }

            .content--movement-side {
                background: none !important; // Killing load of precise desktop selectors for background images
                display: none;
            }
        }
    }

    .sect-iap-list {
        background-image: url('#{$image-path}/sections/content-iap-list.jpg');
        background-color: #71d4ed;
    }
}
