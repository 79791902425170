//
// Header styles
////////////////////////////////////////////////////////////////////////////////

.header {
    position: absolute;
    top: $gus-height-desktop;
    left: 0;
    width: 100%;
    height: 202px;
    z-index: 100;
    /*transition: top 0.3s ease-out;*/

    @include range-breakpoint(865px, $screen-md) {
        height: 181px;
    }

    @include range-breakpoint($screen-sm, 864px) {
        height: 157px;
    }

    @include max-breakpoint ($screen-xs-max) {
        height: 122px;
    }

    @include max-breakpoint (720px) {
        top: $gus-height-mobile;
    }
}

body.has-smartbanner {
    .header {
        top: $gus-height-desktop-smartbanner;

        @include range-breakpoint(865px, $screen-md) {
            height: 259px;
        }

        @include range-breakpoint($screen-sm, 864px) {
            height: 235px;
        }

        @include max-breakpoint ($screen-xs-max) {
            height: 200px;
        }

        @include max-breakpoint (720px) {
            top: $gus-height-mobile-smartbanner;
        }
    }
}

.header--logo {
    width: 294px;
    height: auto;
    padding-top: 17px;
    margin: 0 auto;
    z-index: 2;
    position: relative;

    a {
        display: block;
        width: 100%;
        color: transparent;

        img {
            @include block-image();
            transform: scale(1);
            transition: transform 0.1s ease-out;
        }

        &:hover {
            img {
                transform: scale(1.1);
                transform-origin: center;
            }
        }
    }

    @include range-breakpoint(865px, $screen-md) {
        width: 240px;
    }

    @include range-breakpoint($screen-sm, 874px) {
        width: 200px;
    }

    @include max-breakpoint ($screen-xs-max) {
        padding-top: 27px;
        width: 175px;
    }
}

body[data-page="homepage"] {
    .header--logo {
        @include range-breakpoint(865px, $screen-md) {
            width: 240px;
        }

        @include range-breakpoint($screen-sm, 874px) {
            width: 200px;
        }

        @include max-breakpoint ($screen-xs-max) {
            padding-top: 34px;
            width: 230px;
        }

        @include max-breakpoint (400px) {
            padding-top: 40px;
        }
    }
}

//
// Sticky header/nav
//

@include min-breakpoint($screen-sm) {
    .header {
        &.is-stuck {
            transition: top 0.3s ease-out;
        }
        &.is-sticky {
            position: fixed;
            top: 0 !important;
            height: 87px;

            .header--logo {
                width: 137px;
            }
        }

        &.is-up {
            position: fixed;
            top: -202px !important;
        }

        &.is-sticky.is-up {
            position: fixed;
            top: -202px !important;
        }
    }
}
