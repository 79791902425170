//
// French
////////////////////////////////////////////////////////////////////////////////

html[lang="fr"] {
    // appropriate app store language
    .btn-appstore-apple {
        background-position: 0 -40px;
    }
    .btn-appstore-google {
        background-position: -138px -40px;
    }

    // keep nav items on 2 lines max
    .navigation--purchases {
        a {
            font-size: 14px;
        }
    }

    @include range-breakpoint($screen-sm, $nav-small-breakpoint) {
        .navigation--purchases {
            a {
                font-size: 12px;
            }

            a:after {
                margin-top: 5px;
                position: relative;
                left: -1px;
            }
        }
    }

    // social box w/ long label cleanup
    .footer--social-box {
        span {
            display: block;
            margin-bottom: 4px;
        }

        li:first-child {
            margin-left: 0;
        }
    }

    // homepage hero vertical sizing on mobile
    body[data-page="homepage"] {
        // overwrite phone text on homepage
        .phone-display h3 {
            font-size: 28px;
        }

        @include range-breakpoint($screen-sm, 990px) {
            .content-hero h1 {
                font-size: 90px;
            }
            .btn.btn-play {
                transform: scale(0.85);
            }
            .phone-display h3 {
                font-size: 24px;
            }
        }
        @include range-breakpoint($screen-sm, 860px) {
            .content-hero h1 {
                font-size: 85px;
            }
            .btn.btn-play {
                transform: scale(0.8);
            }
            .phone-display h3 {
                font-size: 22px;
            }
        }
        @include range-breakpoint($screen-sm, 800px) {
            .content-hero h1 {
                font-size: 80px;
            }
            .btn.btn-play {
                transform: scale(0.7);
            }
            .phone-display h3 {
                font-size: 22px;
            }
            .content-hero--callout {
                font-size: 15px;
            }
        }

        @include max-breakpoint($screen-xs-max) {
            // reduce news header
            .content--header h2 {
                font-size: 16px;
            }
            .btn.btn-play {
                transform: translateX(-53%) scale(1);
            }
            .content-hero {
                h1 {
                    font-size: 55px;
                }
                &--background {
                    height: 403px;
                }
                &::after {
                    top: 251px;
                }
                .content--movement {
                    height: calc(100% + 499px);
                }

                &.has-video {
                    .content-hero--background {
                        height: 418px;
                    }
                    &::after {
                        top: 266px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 463px;
                    }
                    &::after {
                        top: 311px;
                    }
                    .content--movement {
                        height: calc(100% + 499px);
                    }

                    &.has-video {
                        .content-hero--background {
                            height: 480px;
                        }
                        &::after {
                            top: 328px;
                        }
                        .content--movement {
                            height: calc(100% + 529px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint(638px) {
            .btn.btn-play {
                transform: translateX(-49%) scale(0.9);
            }
        }

        @include max-breakpoint(560px) {
            .btn.btn-play {
                transform: translateX(-46%) scale(0.85);
            }
        }

        @include max-breakpoint(536px) {
            .btn.btn-play {
                transform: translateX(-43%) scale(0.8);
            }
            .content-hero {
                &--background {
                    height: 458px;
                }
                &::after {
                    top: 306px;
                }
                .content--movement {
                    height: calc(100% + 499px);
                }

                &.has-video {
                    .content-hero--background {
                        height: 471px;
                    }
                    &::after {
                        top: 319px;
                    }
                    .content--movement {
                        height: calc(100% + 532px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 518px;
                    }
                    &::after {
                        top: 366px;
                    }
                    .content--movement {
                        height: calc(100% + 499px);
                    }

                    &.has-video {
                        .content-hero--background {
                            height: 535px;
                        }
                        &::after {
                            top: 383px;
                        }
                        .content--movement {
                            /*height: calc(100% + 523px);*/
                        }
                    }
                }
            }
        }

        @include max-breakpoint(500px) {
            .btn.btn-play {
                transform: translateX(-39%) scale(0.7);
            }
            .content-hero {
                &.has-video {
                    .content-hero--background {
                        height: 471px;
                    }
                    &::after {
                        top: 319px;
                    }
                    .content--movement {
                        height: calc(100% + 533px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &.has-video {
                        .content-hero--background {
                            /*height: 531px;*/
                        }
                        &::after {
                            /*top: 379px;*/
                        }
                        .content--movement {
                            /*height: calc(100% + 523px);*/
                        }
                    }
                }
            }
        }

        @include max-breakpoint(440px) {
            .btn.btn-play {
                transform: translateX(-37%) scale(0.65);
            }
            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 563px;
                    }
                    &::after {
                        top: 411px;
                    }
                    .content--movement {
                        height: calc(100% + 499px);
                    }
                    &.has-video {
                        .content-hero--background {
                            height: 580px;
                        }
                        &::after {
                            top: 428px;
                        }
                        .content--movement {
                            /*height: calc(100% + 523px);*/
                        }
                    }
                }
            }
        }

        @include max-breakpoint(422px) {
            .btn.btn-play {
                transform: translateX(-34%) scale(0.6);
            }
            .content-hero {
                &--background {
                    height: 513px;
                }
                &::after {
                    top: 361px;
                }
                .content--movement {
                    height: calc(100% + 499px);
                }

                &.has-video {
                    .content-hero--background {
                        height: 526px;
                    }
                    &::after {
                        top: 374px;
                    }
                    .content--movement {
                        height: calc(100% + 533px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 618px;
                    }
                    &::after {
                        top: 466px;
                    }
                    .content--movement {
                        height: calc(100% + 499px);
                    }

                    &.has-video {
                        .content-hero--background {
                            height: 635px;
                        }
                        &::after {
                            top: 483px;
                        }
                        .content--movement {
                            /*height: calc(100% + 523px);*/
                        }
                    }
                }
            }
        }

        @include max-breakpoint(390px) {
            .btn.btn-play {
                transform: translateX(-32%) scale(0.55);
            }
        }

        @include max-breakpoint(350px) {
            .btn.btn-play {
                transform: translateX(-30%) scale(0.5);
            }
            .content-hero {
                &.has-video {
                    .content-hero--background {
                        height: 541px;
                    }
                    &::after {
                        top: 389px;
                    }
                    .content--movement {
                        height: calc(100% + 533px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &.has-video {
                        .content-hero--background {
                            height: 650px;
                        }
                        &::after {
                            top: 498px;
                        }
                        .content--movement {
                            /*height: calc(100% + 523px);*/
                        }
                    }
                }
            }
        }

        // LOCALIZED IMAGE OVERWRITES
        .section-home-explore {
            .content--movement-side {
                width: 520px;
                height: 624px;
                background-image: url('#{$image-path}/sections/side-home-explore-fr.png');
                left: 46%;
                top: auto;
                bottom: -60px;
                z-index: 2;
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-home-explore-mobile-fr.jpg');
            }
        }
    }

    body[data-page="go-plus"] {
        .content-side-text {
            h2 {
                @include min-breakpoint($screen-sm) {
                    font-size: 28px;
                }

                @include min-breakpoint($screen-md) {
                    font-size: 30px;
                }
            }
        }

        // LOCALIZED IMAGE OVERWRITES
        .sect-goplus-alerts {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-goplus-alerts-fr.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-goplus-alerts-mobile-fr.jpg');
            }
        }
    }

    body[data-page="explore"] {
        // LOCALIZED IMAGE OVERWRITES
        .content.sect-explore-maps {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-maps-fr.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-maps-mobile-fr.jpg');
            }
        }

        .content.sect-explore-encounter {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-encounter-fr.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-encounter-mobile-fr.jpg');
            }
        }

        .content.sect-explore-pokestops {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-pokestops-fr.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-pokestops-mobile-fr.jpg');
            }
        }

        .content.sect-explore-bag {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-bag-fr.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-bag-mobile-fr.jpg');
            }
        }

        .content.sect-explore-trainer {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-trainer-fr.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-trainer-mobile-fr.jpg');
            }
        }

        .content.sect-explore-medals {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-explore-medals-fr.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-explore-medals-mobile-fr.jpg');
            }
        }
    }

    body[data-page="pokemon"] {
        // LOCALIZED IMAGE OVERWRITES
        .sect-pokemon-catch {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-pokemon-catch-fr.png');
            }
        }

        .sect-pokemon-pokedex {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-pokemon-pokedex-fr.png');
            }
        }

        .sect-pokemon-traits {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-pokemon-traits-fr.png');
            }
        }

        .sect-pokemon-evolution {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-pokemon-evolution-fr.png');
            }
        }

        .sect-pokemon-eggs {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-pokemon-eggs-fr.png');
            }
        }

        @include max-breakpoint($screen-xs-max) {
            .sect-pokemon-catch {
                background-image: url('#{$image-path}/sections/content-pokemon-catch-mobile-fr.jpg');
            }

            .sect-pokemon-pokedex {
                background-image: url('#{$image-path}/sections/content-pokemon-pokedex-mobile-fr.jpg');
            }

            .sect-pokemon-traits {
                background-image: url('#{$image-path}/sections/content-pokemon-traits-mobile-fr.jpg');
            }

            .sect-pokemon-evolution {
                background-image: url('#{$image-path}/sections/content-pokemon-evolution-mobile-fr.jpg');
            }
            .sect-pokemon-eggs {
                background-image: url('#{$image-path}/sections/content-pokemon-eggs-mobile-fr.jpg');
            }
        }
    }

    body[data-page="teams-gyms"] {
        // LOCALIZED IMAGE OVERWRITES
        .sect-teams-main {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-main-fr.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-main-mobile-fr.jpg');
            }
        }

        .sect-teams-gyms {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-gyms-fr.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-gyms-mobile-fr.jpg');
            }
        }

        .sect-teams-prestige {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-prestige-fr.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-prestige-mobile-fr.jpg');
            }
        }

        .sect-teams-training {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-training-fr.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-training-mobile-fr.jpg');
            }
        }

        .sect-teams-attacking {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-attacking-fr.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-attacking-mobile-fr.jpg');
            }
        }

        .sect-teams-defending {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-teams-defending-fr.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-teams-defending-mobile-fr.jpg');
            }
        }
    }

    body[data-page="iap"] {
        // LOCALIZED IMAGE OVERWRITES
        .sect-iap-main {
            .content--movement-side {
                background-image: url('#{$image-path}/sections/side-iap-main-fr.png');
            }

            @include max-breakpoint($screen-xs-max) {
                background-image: url('#{$image-path}/sections/content-iap-main-mobile-fr.jpg');
            }
        }
    }
}
