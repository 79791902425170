//
// Netherlands
////////////////////////////////////////////////////////////////////////////////

html[lang="nl"] {

    // appropriate app store language
    .btn-appstore-apple {
        background-position: 0 -160px;
    }
    .btn-appstore-google {
        background-position: -138px -160px;
    }

    .navigation--purchases {
        a {
            font-size: 14px;
        }
    }
    .modal-scrollable {
        .modal--content {
            h2 {
                @include max-breakpoint(460px) {
                    font-size: 20px;
                }
                @include max-breakpoint(360px) {
                    font-size: 17px;
                }
            }
        }
    }

    @include range-breakpoint(865px, $screen-md) {
        .navigation--wrapper a::after {
            margin-top: 15px;
        }
        .navigation--purchases {
            a {
                font-size: 12px;
            }
        }
    }

    @include range-breakpoint($screen-sm, $nav-small-breakpoint) {
        .navigation--wrapper a::after {
            margin-top: 10px;
        }
        .navigation--purchases {
            a {
                word-break: break-all;

                &:after {
                    position: relative;
                    left: -1px;
                }
            }
        }
    }

    @include range-breakpoint ($nav-small-height-breakpoint, $nav-small-breakpoint) {
        .navigation--wrapper a::after {
            margin-top: 13px;
        }
    }

    // homepage hero vertical sizing on mobile
    body[data-page="homepage"] {
        .content--tagline {
            font-size: 48px !important;

            @include max-breakpoint(900px) {
                font-size: 42px !important;
            }

            @include max-breakpoint($screen-xs-max) {
                font-size: 30px !important;
            }
        }

        @include range-breakpoint($screen-sm, 970px) {
            .content-hero h1 {
                font-size: 95px;
            }
            .btn.btn-play {
                transform: scale(0.9);
            }
            .phone-display h3 {
                font-size: 28px;
            }
        }

        @include range-breakpoint($screen-sm, 920px) {
            .content-hero h1 {
                font-size: 80px;
            }
            .btn.btn-play {
                transform: scale(0.8);
            }
            .phone-display h3 {
                font-size: 24px;
            }
            .content-hero--callout {
                font-size: 15px;
            }
        }

        @include range-breakpoint($screen-sm, 790px) {
            .content-hero h1 {
                font-size: 75px;
            }
            .btn.btn-play {
                transform: scale(0.75);
            }
            .phone-display h3 {
                font-size: 22px;
            }
            .content-hero--callout {
                font-size: 14px;
            }
        }

        // override homepage section headline font sizing
        @include min-breakpoint(901px) {
            .content--tagline {
                font-size: 65px !important;
            }
        }


        @include max-breakpoint($screen-xs-max) {
            // reduce news header
            .content--header h2 {
                font-size: 16px;
            }
            .btn.btn-play {
                transform: translateX(-54%) scale(1);
            }
            .content-hero {
                &--background {
                    height: 421px;
                }
                &::after {
                    top: 269px;
                }
                .content--movement {
                    height: calc(100% + 492px);
                }
                &.has-video {
                    .content-hero--background {
                        height: 430px;
                    }
                    &::after {
                        top: 278px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }
            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 481px;
                    }
                    &::after {
                        top: 329px;
                    }
                    .content--movement {
                        height: calc(100% + 492px);
                    }
                    &.has-video {
                        .content-hero--background {
                            height: 492px;
                        }
                        &::after {
                            top: 340px;
                        }
                        .content--movement {
                            height: calc(100% + 528px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint(719px) {
            .content-hero {
                &--background {
                    height: 487px;
                }
                &::after {
                    top: 335px;
                }
                .content--movement {
                    height: calc(100% + 492px);
                }
                &.has-video {
                    .content-hero--background {
                        height: 496px;
                    }
                    &::after {
                        top: 344px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }
            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 547px;
                    }
                    &::after {
                        top: 395px;
                    }
                    .content--movement {
                        height: calc(100% + 492px);
                    }
                    &.has-video {
                        .content-hero--background {
                            height: 558px;
                        }
                        &::after {
                            top: 406px;
                        }
                        .content--movement {
                            height: calc(100% + 528px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint(550px) {
            .btn.btn-play {
                transform: translateX(-51%) scale(0.9);
            }
        }

        @include max-breakpoint(488px) {
            .btn.btn-play {
                transform: translateX(-45%) scale(0.8);
            }
            .content-hero {
                &--background {
                    height: 532px;
                }
                &::after {
                    top: 380px;
                }
                .content--movement {
                    height: calc(100% + 492px);
                }
                &.has-video {
                    .content-hero--background {
                        height: 541px;
                    }
                    &::after {
                        top: 389px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }
            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        /*height: 421px;*/
                    }
                    &::after {
                        /*top: 269px;*/
                    }
                    .content--movement {
                        /*height: calc(100% + 492px);*/
                    }
                    &.has-video {
                        .content-hero--background {
                            /*height: 492px;*/
                        }
                        &::after {
                            /*top: 340px;*/
                        }
                        .content--movement {
                            /*height: calc(100% + 577px);*/
                        }
                    }
                }
            }
        }

        @include max-breakpoint(450px) {
            .btn.btn-play {
                transform: translateX(-40%) scale(0.7);
            }
        }

        @include max-breakpoint(380px) {
            .btn.btn-play {
                transform: translateX(-36%) scale(0.6);
            }
            .content-hero {
                &--background {
                    height: 598px;
                }
                &::after {
                    top: 446px;
                }
                .content--movement {
                    height: calc(100% + 492px);
                }
                &.has-video {
                    .content-hero--background {
                        height: 607px;
                    }
                    &::after {
                        top: 455px;
                    }
                    .content--movement {
                        /*height: calc(100% + 579px);*/
                    }
                }
            }
            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 613px;
                    }
                    &::after {
                        top: 461px;
                    }
                    .content--movement {
                        height: calc(100% + 492px);
                    }
                    &.has-video {
                        .content-hero--background {
                            height: 624px;
                        }
                        &::after {
                            top: 472px;
                        }
                        .content--movement {
                            height: calc(100% + 528px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint(350px) {
            .btn.btn-play {
                transform: translateX(-31%) scale(0.5);
            }
            .content-hero {
                &.has-video {
                    .content-hero--background {
                        height: 622px;
                    }
                    &::after {
                        top: 470px;
                    }
                    .content--movement {
                        /*height: calc(100% + 579px);*/
                    }
                }
            }
            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &.has-video {
                        .content-hero--background {
                            height: 639px;
                        }
                        &::after {
                            top: 487px;
                        }
                        .content--movement {
                            height: calc(100% + 528px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint(333px) {
            .content-hero {
                &--background {
                    height: 586px;
                }
                &::after {
                    top: 434px;
                }
                .content--movement {
                    height: calc(100% + 492px);
                }
                &.has-video {
                    .content-hero--background {
                        height: 610px;
                    }
                    &::after {
                        top: 458px;
                    }
                    .content--movement {
                        /*height: calc(100% + 579px);*/
                    }
                }
            }
            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 601px;
                    }
                    &::after {
                        top: 449px;
                    }
                    .content--movement {
                        height: calc(100% + 492px);
                    }
                    &.has-video {
                        .content-hero--background {
                            height: 627px;
                        }
                        &::after {
                            top: 475px;
                        }
                        .content--movement {
                            height: calc(100% + 528px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint(326px) {
            .content-hero {
                &.has-video {
                    .content-hero--background {
                        /*height: 430px;*/
                    }
                    &::after {
                        /*top: 278px;*/
                    }
                    .content--movement {
                        /*height: calc(100% + 579px);*/
                    }
                }
            }
            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 646px;
                    }
                    &::after {
                        top: 494px;
                    }
                    .content--movement {
                        height: calc(100% + 492px);
                    }
                    &.has-video {
                        .content-hero--background {
                            /*height: 492px;*/
                        }
                        &::after {
                            /*top: 340px;*/
                        }
                        .content--movement {
                            /*height: calc(100% + 577px);*/
                        }
                    }
                }
            }
        }
    }
}
