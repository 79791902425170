//
// Russian
////////////////////////////////////////////////////////////////////////////////

html[lang="ru"] {

    // appropriate app store language
    .btn-appstore-apple {
        background-position: 0 -240px;
    }
    .btn-appstore-google {
        background-position: -138px -240px;
    }
    .navigation--wrapper {
        a {
            font-size: 16px;
            line-height: 1.125;

            &:after {
                /*margin-top: 15px;*/
            }
        }
    }
    .navigation--purchases {
        a {
            font-size: 14px;
            line-height: 16px;

            &:after {
                margin-top: 10px;
            }
        }
    }
    .navigation--explore {
        a {
            font-size: 14px;
            line-height: 16px;

            &:after {
                margin-top: 10px;
            }
        }
    }

    @include range-breakpoint($screen-md, 99999px) {
        .header.is-sticky {
            .navigation--plus {
                bottom: -3px;
            }
            .navigation--pokemon {
                bottom: -3px;
            }
            .navigation--teams {
                bottom: -3px;
            }
            .navigation--photos {
                bottom: -3px;
            }
        }
    }

    // navigation
    @include range-breakpoint(865px, $screen-md) {
        .navigation--wrapper {
            a {
                font-size: 14px;
                line-height: 18px;

                &:after {
                    /*margin-top: 15px;*/
                }
            }
        }
    }

    @include range-breakpoint($screen-sm, $nav-small-breakpoint) {
        .navigation--photos {
            a {
                font-size: 12px;
                line-height: 12px;

                &:after {
                    margin-top: 20px;
                }
            }
        }
        .navigation--purchases {
            a {
                font-size: 12px;
                line-height: 12px;
                word-break: break-all;

                &:after {
                    margin-top: 5px;
                }
            }
        }
    }

    @include range-breakpoint($screen-sm, $nav-small-height-breakpoint) {
        .navigation--wrapper {
            a {
                font-size: 12px;
                line-height: 14px;
                margin-top: -3px;

                &:after {
                    margin-top: 8px;
                }
            }
        }
        .navigation--photos {
            a {
                font-size: 12px;
                line-height: 12px;

                &:after {
                    margin-top: 20px;
                }
            }
        }
        .navigation--purchases {
            a {
                font-size: 12px;
                line-height: 12px;
                word-break: break-all;

                &:after {
                    margin-top: 5px;
                }
            }
        }
        .navigation--explore {
            a {
                font-size: 12px;
                line-height: 12px;
                padding-top: 3px;

                &:after {
                    margin-top: 2px;
                }
            }
        }
    }

    // links in footer too big, make smaller
    .footer--column {
        p, a {
            font-size: 14px;
        }
    }

    // social box w/ long label cleanup
    .footer--social-box {
        span {
            display: block;
            margin-bottom: 4px;
        }

        li:first-child {
            margin-left: 0;
        }
    }


    // homepage hero vertical sizing on mobile
    body[data-page="homepage"] {
        // overwrite hero gradient text sizing
        .content-hero {
            h1 {
                font-size: 95px;
            }
        }

        // overwrite phone text on homepage
        .phone-display h3 {
            font-size: 28px;
        }


        @include range-breakpoint($screen-sm, 890px) {
            .btn.btn-play {
                transform: scale(0.9);
            }
        }

        @include range-breakpoint($screen-sm, 790px) {
            .content-hero h1 {
                font-size: 85px;
            }
            .btn.btn-play {
                transform: scale(0.85);
            }
            // overwrite phone text on homepage
            .phone-display h3 {
                font-size: 24px;
            }
        }

        @include max-breakpoint($screen-xs-max) {
            .btn.btn-play {
                transform: translateX(-55%) scale(1);
            }
            .content-hero {
                &--background {
                    height: 538px;
                }
                &::after {
                    top: 386px;
                }
                .content--movement {
                    height: calc(100% + 499px);
                }

                &.has-video {
                    .content-hero--background {
                        height: 590px;
                    }
                    &::after {
                        top: 438px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 598px;
                    }
                    &::after {
                        top: 446px;
                    }
                    .content--movement {
                        height: calc(100% + 499px);
                    }

                    &.has-video {
                        .content-hero--background {
                            height: 614px;
                        }
                        &::after {
                            top: 462px;
                        }
                        .content--movement {
                            height: calc(100% + 530px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint (540px) {
            .btn.btn-play {
                transform: translateX(-50%) scale(0.9);
            }
        }

        @include max-breakpoint (480px) {
            .btn.btn-play {
                transform: translateX(-46%) scale(0.8);
            }
            .content-hero {
                h1 {
                    font-size: 80px;
                }
                &--background {
                    height: 508px;
                }
                &::after {
                    top: 356px;
                }
                .content--movement {
                    height: calc(100% + 499px);
                }

                &.has-video {
                    .content-hero--background {
                        height: 524px;
                    }
                    &::after {
                        top: 372px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 568px;
                    }
                    &::after {
                        top: 416px;
                    }
                    .content--movement {
                        height: calc(100% + 499px);
                    }

                    &.has-video {
                        .content-hero--background {
                            height: 625px;
                        }
                        &::after {
                            top: 473px;
                        }
                        .content--movement {
                            height: calc(100% + 530px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint (540px) {
            .btn.btn-play {
                transform: translateX(-41%) scale(0.7);
            }
        }

        @include max-breakpoint (413px) {
            .content-hero {
                h1 {
                    font-size: 70px;
                }
                &--background {
                    height: 533px;
                }
                &::after {
                    top: 381px;
                }
                .content--movement {
                    height: calc(100% + 499px);
                }

                &.has-video {
                    .content-hero--background {
                        height: 549px;
                    }
                    &::after {
                        top: 397px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 548px;
                    }
                    &::after {
                        top: 396px;
                    }
                    .content--movement {
                        height: calc(100% + 499px);
                    }

                    &.has-video {
                        .content-hero--background {
                            height: 605px;
                        }
                        &::after {
                            top: 453px;
                        }
                        .content--movement {
                            height: calc(100% + 530px);
                        }
                    }
                }
            }
        }

        @include max-breakpoint (380px) {
            .btn.btn-play {
                transform: translateX(-37%) scale(0.6);
            }
        }

        @include max-breakpoint (360px) {
            .content-hero {
                h1 {
                    font-size: 60px;
                }
                h3 {
                    font-size: 28px;
                }
                &--background {
                    height: 502px;
                }
                &::after {
                    top: 350px;
                }
                .content--movement {
                    height: calc(100% + 499px);
                }

                &.has-video {
                    .content-hero--background {
                        height: 518px;
                    }
                    &::after {
                        top: 366px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {
                    &--background {
                        height: 523px;
                    }
                    &::after {
                        top: 371px;
                    }
                    .content--movement {
                        height: calc(100% + 499px);
                    }

                    &.has-video {
                        .content-hero--background {
                            height: 575px;
                        }
                        &::after {
                            top: 423px;
                        }
                        .content--movement {
                            height: calc(100% + 530px);
                        }
                    }
                }
            }
        }



        @include max-breakpoint (350px) {
            .btn.btn-play {
                transform: translateX(-34.5%) scale(0.58);
            }
            .content-hero {
                &.has-video {
                    .content-hero--background {
                        height: 533px;
                    }
                    &::after {
                        top: 381px;
                    }
                    .content--movement {
                        height: calc(100% + 530px);
                    }
                }
            }

            &[data-app-release-state="postrelease"] {
                .content-hero {

                    &.has-video {
                        .content-hero--background {
                            height: 590px;
                        }
                        &::after {
                            top: 438px;
                        }
                        .content--movement {
                            height: calc(100% + 530px);
                        }
                    }
                }
            }
        }
    }

    body[data-page="go-plus"] {
        .sect-goplus-main {
            h2 {
                font-size: 26px;

                @include min-breakpoint($screen-sm) {
                    font-size: 20px;
                }

                @include min-breakpoint($screen-lg) {
                    font-size: 26px;
                }
            }
        }
    }

    .compatibility {
        &--header {
            .btn {
                line-height: 1.2;
                white-space: normal;
            }
        }
    }
}
