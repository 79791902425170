//
// News Details Page
////////////////////////////////////////////////////////////////////////////////////////////////

body[data-page="news-details"] {
    .news-details {
        &--social-box {
            font-size: 0;
            margin-left: 10px;

            ul {
                font-size: 0;
            }

            span {
                @include inline-middle();
                font-size: 15px;
            }

            li {
                margin-left: 10px;
            }

            &:first-child {
                margin-left: 0;
            }
        }

        &--utility {
            @include clearfix();
            padding-bottom: 20px;

            .content--news-link {
                .icon-caret-right-circle {
                    margin-right: 7px;
                    transform: rotate(180deg);
                }
            }

            .news-details--social-box {
              margin-left: 0;
            }

            @include min-breakpoint($screen-sm) {
                .content--news-link {
                    float: left;
                    padding-top: 14px;
                }

                .news-details--social-box {
                    float: right;
                }
            }
        }
    }

    .news-article--headline {
        color: $color-blue;
    }

    .news-article--image {
        img {
            display: none;
        }

        img.news-article--image-horizontal {
            display: block;
            max-width: 286px;
        }

        img.news-article--image-vertical {
            display: block;
            max-width: 173px;
        }
    }
}
