//
// Animations
////////////////////////////////////////////////////////////////////////////////

@keyframes bg-img-anim {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 148px 0;
    }
}

.animate [data-movement-element] {
    animation-name: bg-img-anim;
    animation-duration: 7s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-delay: 0;
}

@keyframes bounce-scale {
  0%, 20%, 50%, 80%, 100% {
    transform: scale(1);
  }

  20% {
    transform: scale(1.2);
  }

  80% {
    transform: scale(1.05);
  }
}
