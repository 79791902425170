//
// Region Select box in footer
////////////////////////////////////////////////////////////////////////////////

$color-region-border: #2782d2;

.region-select {
    @include inline-middle();
    background: linear-gradient(to bottom, rgba(75,208,235,1) 0%,rgba(47,173,225,1) 100%);
    border: 1px #fff solid;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    line-height: 1;
    height: 40px;
    padding: 10px;
    position: relative;
    text-align: left;
    width: auto;
    white-space: nowrap;

    &--label {
        @include inline-middle();
        color: #fff;
        font-family: $font-family-special;
        font-weight: 700;
        text-transform: uppercase;
    }

    &--current-selection {
        @include inline-middle();
        background: #000;
        font-size: 0;
        height: 16px;
        margin: 0 10px;
        width: 24px;
    }
}

.header--region-select {
    background-color: $color-region-border;
    height: 4px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;

    .is-stuck & {
        display: none;
    }

    .region-select {
        background: $color-region-border;
        border: 0;
        border-radius: 0;
        height: 25px;
        left: 0;
        padding: 2px;
        position: absolute;
        top: 3px;
        width: 65px;

        &::before,
        &::after {
            content: '';
            background-image: url($image-sprite);
            background-repeat: no-repeat;
            bottom: 0;
            display: block;
            height: 24px;
            position: absolute;
            width: 49px;
        }

        &::before {
            background-position: -720px -616px;
            left: -49px;
        }

        &::after {
            background-position: -769px -616px;
            right: -49px;
        }
    }

    @include range-breakpoint(721px, 767px) {
        top: -5px;
    }

    @include min-breakpoint($screen-sm) {
        .region-select {
            left: 50%;
            transform: translateX(-160px);
        }
    }

    @include min-breakpoint($screen-md) {
        .region-select {
            left: 50%;
            transform: translateX(-200px);
        }
    }
}
