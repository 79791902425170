//
// news summary
////////////////////////////////////////////////////////////////////////////////

.news-summary {
    border-radius: 4px;
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.45) 100%);
    padding: 16px 18px 9px 18px;
    font-size: 0;
    display: block;
    text-decoration: none;
    min-height: 68px;
    text-align: left;

    &--date {
        display: inline-block;
        vertical-align: top;
        color: $color-green-light;
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        width: 36px;
        float: left;
        margin-right: 14px;
        line-height: 1;

        &[data-length="4"] {
            font-size: 12px;
        }

        &[data-length="5"] {
            font-size: 10px;
        }

        strong {
            font-size: 23px;
            display: block;
            font-weight: 900;
        }

        &.news-summary--date-day-first {
            padding-top: 23px;
            position: relative;

            strong {
                position: absolute;
                top: -1px;
                left: 0;
                width: 100%;
                text-align: center;
            }
        }
    }

    .news-summary--headline {
        display: inline-block;
        vertical-align: top;
        font-size: 16px;
        font-weight: 700;
        width: calc(100% - 50px);
        color: $color-dark-grey;
        text-transform: none;
        margin: 0;
        position: relative;
        top: -4px;
        line-height: 1.35;
        text-align: left;
        text-decoration: none;
        text-shadow: none;

        .icon {
            position: relative;
            top: -2px;
        }
    }

    &:hover {
        .news-summary--headline {
            text-decoration: underline;
        }
    }

    @include max-breakpoint($screen-xs-max) {
        margin-top: 24px;
    }
}

@include max-breakpoint($screen-xs-max) {
    *:first-child > .news-summary {
        margin-top: 0;
    }
}
