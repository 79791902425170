//
// Grid system. Bootstrap grid only
////////////////////////////////////////////////////////////////////////////////


// variables for grid stuff from  in _config.scss


//
// imports
////////////////////////////////////////////////////////////////////////////////

@import "bootstrap/stylesheets/bootstrap/mixins/clearfix";

@import "bootstrap/stylesheets/bootstrap/mixins/responsive-visibility";

@import "bootstrap/stylesheets/bootstrap/mixins/grid-framework";

@import "bootstrap/stylesheets/bootstrap/mixins/grid";

@import "bootstrap/stylesheets/bootstrap/grid";

@import "bootstrap/stylesheets/bootstrap/responsive-utilities";

.row > div {
    box-sizing: border-box;
}
