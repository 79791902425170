//
// Mixins
////////////////////////////////////////////////////////////////////////////////

@mixin debug () {
	background-color: $debug;
}

@mixin accelerate() {
	transform: translate3d(0,0,0);
	backface-visibility: hidden;
	perspective: 1000;
}

@mixin bind-width () {
	max-width: $content-max-width;
	margin-left: auto;
	margin-right: auto;
}

@mixin sprite ($bg-position) {
	overflow: hidden;
    background-color: transparent;
	background-image: url($image-sprite);
	background-repeat: no-repeat;
    background-position: $bg-position;
    background-size: $image-sprite-background-width auto;
	display: inline-block;
}

@mixin image-2x($image, $width, $height) {
	@media (min--moz-device-pixel-ratio: 1.3),
	(-o-min-device-pixel-ratio: 2.6/2),
	(-webkit-min-device-pixel-ratio: 1.3),
	(min-device-pixel-ratio: 1.3),
	(min-resolution: 1.3dppx) {
		background-image: url($image);
		background-size: $width $height;
	}
}

@mixin clearfix() {
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}

@mixin center-block($width) {
	width: $width;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

@mixin inline-middle () {
	display: inline-block;
	vertical-align: middle;
}

@mixin inline-base () {
	display: inline-block;
	vertical-align: baseline;
}

@mixin inline-top () {
	display: inline-block;
	vertical-align: top;
}

@mixin block-image () {
	width: 100%;
	height: auto;
	display: block;
}

@mixin vertical-linear-gradient($fromColor, $toColor) {
	background-color: $toColor;
	background-image: -webkit-gradient(linear, left top, left bottom, from($fromColor), to($toColor));
	background-image: -webkit-linear-gradient(top, $fromColor, $toColor);
	background-image: -moz-linear-gradient(top, $fromColor, $toColor);
	background-image: -ms-linear-gradient(top, $fromColor, $toColor);
	background-image: -o-linear-gradient(top, $fromColor, $toColor);
	background-image: linear-gradient(to bottom, $fromColor, $toColor);
}

@mixin horizontal-linear-gradient($fromColor, $toColor) {
	background-color: $toColor;
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,$fromColor), color-stop(100%,$toColor));
	background: -webkit-linear-gradient(left,  $fromColor 0%,$toColor 100%);
	background: -moz-linear-gradient(left,  $fromColor 0%, $toColor 100%);
	background: -ms-linear-gradient(left,  $fromColor 0%,$toColor 100%);
	background: -o-linear-gradient(left,  $fromColor 0%,$toColor 100%);
	background: linear-gradient(to right,  $fromColor 0%,$toColor 100%);
}

@mixin radial-gradient($fromColor, $toColor) {
	background: $toColor;
	background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, $fromColor), color-stop(100%, $toColor));
	background: -moz-radial-gradient(center, ellipse cover, $fromColor 0%, $toColor 100%);
	background: -webkit-radial-gradient(center, ellipse cover, $fromColor 0%, $toColor 100%);
	background: -ms-radial-gradient(center, ellipse cover, $fromColor 0%, $toColor 100%);
	background: -o-radial-gradient(center, ellipse cover, $fromColor 0%, $toColor 100%);
	background: radial-gradient(ellipse at center, $fromColor 0%, $toColor 100%);
}

@mixin fill() {
	width: 100%;
	height: 100%;
}

@mixin inline-top () {
	display: inline-block;
	vertical-align: top;
}

@mixin min-breakpoint($point) {
	@media (min-width: $point)  { @content; }
}

@mixin max-breakpoint($point) {
	@media (max-width: $point)  { @content; }
}

@mixin range-breakpoint($min-point, $max-point) {
	@media (min-width: $min-point) and (max-width: $max-point)  { @content; }
}
